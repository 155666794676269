spc-designoverlay.designoverlay--page
{
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  outline: 1px solid #F00;
  z-index: 500;
  pointer-events: none;

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

spc-designoverlay[hidden]
{
  display: none !important;
}

spc-designoverlay__content
{
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  position: relative; /* so we can use width: 100%; on the absolute positioned image childnode */
}

.spc-designoverlay__info
{
/*  grid-row: 1;*/
  position: absolute;
  z-index: 10;
}

/*
spc-designoverlay__content
{
  grid-column: 1 / -1;
  align-self: center;
  transform: translate(-50%,0);
}
*/
