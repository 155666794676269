.spcgridoverlay__columns
{
  display: none;

  grid-row: 1 / -1;
  grid-column: 1 / -1;
  grid-template-columns: var(--pagegrid-template-columns);
}

/*.debugsettings:has([[name="showgrid"]:checked)*/
html.gridoverlay--enable .spcgridoverlay__columns
{
  display: grid;
}
