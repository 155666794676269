.comp-togglebuttonbar
{
  display: flex;
  flex-wrap: wrap; /* rather not, but it's better than overflowing */
  column-gap: 20px;
  row-gap: 16px;
}

  .comp-togglebuttonbar input[type='checkbox']
, .comp-togglebuttonbar input[type='radio']
{
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
/*
.comp-togglebuttonbar label
{
  color: #FFFFFF;

  border-color: transparent;
  height: 100%;

  background-color: $color-mosque;
  //background-color: $color-green;
}
input:checked + .comp-togglebutton + input:checked + .comp-togglebutton.selected
{
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}
*/
