.site-dialog {
  --formcontrol-checkradio-size: 20px;
  --tagpanel-heading-font: 16px/30px var(--rtd-heading-fontfamily);
}

.dialog__header {
  grid-column: var(--site-menubar-grid-column);
}

.dialog__header__logo {
  width: auto;
  height: var(--site-menubar-logo-height);
}

/*
.site-dialog .filterpanel
{
  margin-top: 30px;
  grid-column: var(--pagegrid-col-fullwidth);
}
*/
.site-dialog .filterpanel .tagcategory__title {
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.site-dialog .filterpanel .tagcategory__title::after {
  padding-top: 5px;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #CBCBCB;
}

/* When we move the full form.peoplepersonpage-filters to the mobile filters popup
   we don't need to see the feedbackbar and tags. We already use the teaser bar.
*/
.site-dialog .filteredoverview__feedbackandviewbar {
  grid-row: 3;
}

/* we already add a reset button in the header */
.site-dialog .filteredoverview-resetbutton {
  display: none;
}

.site-dialog .peoplepersonpage-filters__header .filteredoverview-resetbutton {
  display: block;
}