/*

Issue:
We cannot combine CSS selectors and @media queries.
So we cannot say to always use the accordion layout on a certain page.

Workarounds:
- a) mixins to use in a class and media query (downside: duplication of CSS)
- b) container queries (downside: would require iOS 16+ (sep 2022))
- c) Javascript to set a class determining the main view on the <html> element.
- d) Javascript to set a class on any element with .personslist--autoview by measuring it's container width (work somewhat like the container queries do)

For now we use option C.

*/
/* target for FilteredOverview to replace results in */
.ut-listitems {
  display: contents;
}

.ut-person-accordionheader {
  background: none;
  border: 0;
  text-align: left;
}

.ut-person-accordionheader:focus {
  z-index: 1;
}

.personslist .icon {
  color: var(--overviewlist-icon-color);
}

.ut-person-tile__title {
  color: #1E2328;
  font: 700 18px/24px var(--rtd-heading-fontfamily);
  text-wrap: pretty;
  text-transform: none;
}

.ut-person-tile__title.ut-person-tile__title {
  margin-top: 12px;
  margin-bottom: 0px;
}

.ut-person-tile__orgs div {
  white-space: nowrap;
}

.ut-person-tile__location {
  white-space: nowrap;
}

.ut-person-tile__phone > span {
  display: block;
  white-space: nowrap;
}

.ut-person-tile__mail a {
  color: inherit;
  text-decoration: none;
}

.personslist--accordionview,
html.personslist-default-accordionview .personslist--autoview {
  /* After __names (with title/teamlead) we have a specsheet items */
  /*
  .ut-largeicon_12-pointer:before {
    content: "\e90b";
  }

  .UT-icon-UT_icon_16_pointer-24px:before {
    content: "\e90f";
  }
  */
  /*
  UT-icon-UT_icon_143_company-24px
  */
  /*
  .ut-largeicon_31-phone:before {
    font-family: 'UT-iconfont-48px' !important;
    content: "\e91e";
  }

  .UT-icon-UT_icon_13_phone-24px:before {
    font-family: 'UT-iconfont' !important;
    content: "\e90c";
  }
  */
  /*
  .UT-icon-UT_icon_15_mail-24px:before {
    content: "\e90e";
  }
  */
  /*
  .UT-icon-UT_icon_126_vcard-24px:before {
    content: "\e97d";
  }
  */
}
.personslist--accordionview .ut-person-tile,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  grid-template-rows: min-content 1fr; /* header and specsheet */
  border-bottom: 1px solid var(--common-item-divider-line-color);
}
.personslist--accordionview .ut-person-tile:first-child,
.personslist--accordionview .ut-person-listheader + .ut-person-tile,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile:first-child,
html.personslist-default-accordionview .personslist--autoview .ut-person-listheader + .ut-person-tile {
  border-top: 1px solid var(--common-item-divider-line-color);
}
.personslist--accordionview .ut-person-accordionheader,
html.personslist-default-accordionview .personslist--autoview .ut-person-accordionheader {
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: grid !important;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
}
.personslist--accordionview .ut-person-accordionheader::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-accordionheader::before {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  text-align: center;
  color: #53575b;
  content: var(--accordion-header-symbol-content);
  font: var(--accordion-header-symbol-font);
  font-size: 16px;
  width: 40px;
}
.personslist--accordionview .ut-person-tile[aria-expanded=true] > .ut-person-accordionheader,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile[aria-expanded=true] > .ut-person-accordionheader {
  background: #fafafa;
}
.personslist--accordionview .ut-person-tile__specs > *,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > * {
  background: #FAFAFA;
}
.personslist--accordionview .ut-person-tile[aria-expanded=true],
html.personslist-default-accordionview .personslist--autoview .ut-person-tile[aria-expanded=true] {
  border-bottom-color: #000000;
}
.personslist--accordionview .ut-person-tile:last-child,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile:last-child {
  border-bottom: 1px solid var(--common-item-divider-line-color);
}
.personslist--accordionview .ut-person-tile__image, .personslist--accordionview .ut-person-tile__image-placeholder,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image-placeholder {
  grid-row: 1;
  grid-column: 1;
  margin: var(--largelisting-header-padding-v) 0;
}
.personslist--accordionview .ut-person-accordionheader,
html.personslist-default-accordionview .personslist--autoview .ut-person-accordionheader {
  display: contents;
}
.personslist--accordionview .ut-person-tile__names,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__names {
  grid-row: 1;
  grid-column: 2;
  margin: var(--largelisting-header-padding-v) 0;
  font: 13px/20px var(--rtd-text-fontfamily);
  color: var(--color-black);
}
.personslist--accordionview .ut-person-tile__specs,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs {
  display: none;
}
.personslist--accordionview .ut-person-tile,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile {
  cursor: pointer;
}
.personslist--accordionview .ut-person-tile[aria-expanded=true] .ut-person-accordionheader::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile[aria-expanded=true] .ut-person-accordionheader::before {
  content: var(--accordion-header-symbol-open-content);
}
.personslist--accordionview .ut-person-tile[aria-expanded=true] .ut-person-tile__specs,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile[aria-expanded=true] .ut-person-tile__specs {
  display: contents;
}
.personslist--accordionview .ut-person-tile__specs > *,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > * {
  grid-column: 1/span 3;
  min-height: 50px;
  border-top: 1px solid var(--common-item-divider-line-color);
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.personslist--accordionview .ut-person-tile__names + *,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__names + * {
  border-top: 1px solid #1E2328;
}
.personslist--accordionview .ut-person-tile__image, .personslist--accordionview .ut-person-tile__image-placeholder,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image-placeholder {
  flex: none;
  align-self: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F0F1F2;
  border: 1px solid rgba(0, 0, 0, 0.115);
}
.personslist--accordionview .ut-person-tile__image-placeholder,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image-placeholder {
  align-items: center;
  display: flex;
  justify-content: center;
}
.personslist--accordionview .ut-person-tile__image-placeholder::after,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image-placeholder::after {
  color: #AAACAE;
  content: "\e927";
  font-family: "UT-iconfont" !important;
  font-size: 24px;
  /* Better Font Rendering - making the icon looks a little thinner/crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.personslist--accordionview .ut-person-tile__image, .personslist--accordionview .ut-person-tile__image-placeholder,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__image-placeholder {
  margin-left: 20px;
  margin-right: 25px;
}
.personslist--accordionview .ut-person-tile__specs > *:empty,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > *:empty {
  display: none;
}
.personslist--accordionview .ut-person-tile__specs > *:not(:empty),
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > *:not(:empty) {
  align-items: stretch;
  display: grid;
  grid-template-columns: subgrid;
  padding: 0;
}
.personslist--accordionview .ut-person-tile__specs > *:not(:empty) > *,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > *:not(:empty) > * {
  align-items: center;
  display: grid;
  grid-column: 2/span 2;
  grid-template-columns: subgrid;
}
.personslist--accordionview .ut-person-tile__specs > *:not(:empty) > div,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > *:not(:empty) > div {
  align-items: center;
  cursor: default;
  display: flex;
  max-width: 100%;
  white-space: normal;
}
.personslist--accordionview .ut-person-tile__specs > *:not(:empty)::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs > *:not(:empty)::before {
  grid-column: 1;
  grid-row: 1/span var(--specrows, 1);
  margin-left: 20px;
  margin-right: 25px;
  align-self: center;
  text-align: center;
  font-family: "UT-iconfont" !important;
  color: #AFB1B3;
  font-size: 24px;
  /* Better Font Rendering - making the icon looks a little thinner/crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.personslist--accordionview .ut-person-tile__orgs::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__orgs::before {
  content: "\e98d";
}
.personslist--accordionview .ut-person-tile__location::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__location::before {
  content: "\e90f";
}
.personslist--accordionview .ut-person-tile__phone > a,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__phone > a {
  color: inherit;
  display: flex !important;
  text-decoration: none;
  white-space: nowrap;
}
.personslist--accordionview .ut-person-tile__phone > a > span.label,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__phone > a > span.label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.personslist--accordionview .ut-person-tile__phone > a + span::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__phone > a + span::before {
  content: ", ";
}
.personslist--accordionview .ut-person-tile__phone .label,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__phone .label {
  margin-left: 5px;
}
.personslist--accordionview .ut-person-tile__phone::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__phone::before {
  content: "\e90c";
}
.personslist--accordionview .ut-person-tile__mail::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__mail::before {
  content: "\e90e";
}
.personslist--accordionview .ut-person-tile__mail > a > span,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__mail > a > span {
  display: block;
  grid-column: 1/-1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.personslist--accordionview .ut-person-tile__vcard::before,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__vcard::before {
  content: "\e97d";
}
.personslist--accordionview .ut-person-tile__mail .icon,
.personslist--accordionview .ut-person-tile__vcard .icon,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__mail .icon,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__vcard .icon {
  display: none;
}
.personslist--accordionview .ut-person-tile__specs .ut-person-tile__profilelink,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs .ut-person-tile__profilelink {
  background: var(--color-white);
}
.personslist--accordionview .ut-person-tile__specs .ut-person-tile__profilelink a,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs .ut-person-tile__profilelink a {
  color: inherit;
  grid-column: 1/span 3;
  text-decoration: none;
}
.personslist--accordionview .ut-person-tile__specs .ut-person-tile__profilelink a > span,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs .ut-person-tile__profilelink a > span {
  grid-column: 2;
}
.personslist--accordionview .ut-person-tile__specs .ut-person-tile__profilelink a::after,
html.personslist-default-accordionview .personslist--autoview .ut-person-tile__specs .ut-person-tile__profilelink a::after {
  grid-column: 3;
  text-align: center;
  font-family: "UT-iconfont" !important;
  content: "\e933";
  color: #AAACAE;
  font-size: 16px;
  /* Better Font Rendering - making the icon looks a little thinner/crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.personslist--tableview,
html.personslist-default-tableview .personslist--autoview {
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content min-content min-content min-content;
  --personslist-template-columns: min-content minmax(0,6fr) minmax(0,2fr) minmax(0,4fr) minmax(0,4fr) minmax(0,1fr) minmax(0,1fr);
}
@supports (grid-template-columns: subgrid) {
  .personslist--tableview,
  html.personslist-default-tableview .personslist--autoview {
    --personslist-template-columns: subgrid;
  }
}
.personslist--tableview .ut-person-listheader,
html.personslist-default-tableview .personslist--autoview .ut-person-listheader {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: var(--personslist-template-columns);
  align-items: last baseline;
}
.personslist--tableview .ut-person-listheader > *:not(:last-child),
html.personslist-default-tableview .personslist--autoview .ut-person-listheader > *:not(:last-child) {
  padding-right: 30px;
}
.personslist--tableview .ut-person-listheader__feedback,
html.personslist-default-tableview .personslist--autoview .ut-person-listheader__feedback {
  grid-column: 1/3;
}
.personslist--tableview .ut-person-tile,
html.personslist-default-tableview .personslist--autoview .ut-person-tile {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: var(--personslist-template-columns);
  grid-template-rows: min-content 1fr;
  padding: 14px 10px;
  font: 13px/20px var(--rtd-text-fontfamily);
  border-top: 1px solid #DCDDDE;
  cursor: pointer;
}
.personslist--tableview .ut-person-tile *,
html.personslist-default-tableview .personslist--autoview .ut-person-tile * {
  cursor: pointer;
}
.personslist--tableview .ut-person-tile:last-child,
html.personslist-default-tableview .personslist--autoview .ut-person-tile:last-child {
  border-bottom: 1px solid #DCDDDE;
}
.personslist--tableview .ut-person-accordionheader,
html.personslist-default-tableview .personslist--autoview .ut-person-accordionheader {
  display: contents;
}
.personslist--tableview .ut-person-tile__image, .personslist--tableview .ut-person-tile__image-placeholder,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__image,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__image-placeholder {
  flex: none;
  align-self: center;
  grid-row: 1/span 2;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F0F1F2;
  border: 1px solid rgba(0, 0, 0, 0.115);
  margin-right: 25px;
}
.personslist--tableview .ut-person-tile__image-placeholder,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__image-placeholder {
  align-items: center;
  display: flex;
  justify-content: center;
}
.personslist--tableview .ut-person-tile__image-placeholder::after,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__image-placeholder::after {
  color: #AAACAE;
  content: "\e927";
  font-family: "UT-iconfont" !important;
  font-size: 24px;
  /* Better Font Rendering - making the icon looks a little thinner/crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.personslist--tableview .ut-person-tile__names,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__names {
  display: contents;
}
.personslist--tableview .ut-person-tile__specs,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__specs {
  display: contents;
}
.personslist--tableview .ut-person-tile__title,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__title {
  grid-row: 1;
  grid-column: 2/span 5;
}
.personslist--tableview .ut-person-tile__phone > a,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__phone > a {
  color: inherit;
  text-decoration: none;
}
.personslist--tableview .ut-person-tile__phone > a + span,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__phone > a + span {
  margin-top: 5px;
}
.personslist--tableview .ut-person-tile__phone .title,
.personslist--tableview .ut-person-tile__phone .label,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__phone .title,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__phone .label {
  display: block;
}
.personslist--tableview .ut-person-tile__roles, .personslist--tableview .ut-person-tile__specs > *,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__roles,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__specs > * {
  grid-row: 2;
  align-self: baseline;
}
.personslist--tableview .ut-person-tile__title, .personslist--tableview .ut-person-tile__roles,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__title,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__roles {
  padding-right: 30px;
}
.personslist--tableview .ut-person-tile__names > *,
.personslist--tableview .ut-person-tile__specs > *:not(:last-child),
html.personslist-default-tableview .personslist--autoview .ut-person-tile__names > *,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__specs > *:not(:last-child) {
  padding-right: 30px;
}
.personslist--tableview .ut-person-tile__mail,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__mail {
  text-align: center;
}
.personslist--tableview .ut-person-tile__mail .icon,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__mail .icon {
  font-size: 20px;
}
.personslist--tableview .ut-person-tile__vcard .icon,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__vcard .icon {
  font-size: 21px;
}
.personslist--tableview .ut-person-tile__mail .text,
.personslist--tableview .ut-person-tile__vcard .text,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__mail .text,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__vcard .text {
  display: none;
}
.personslist--tableview .ut-person-tile__profilelink,
html.personslist-default-tableview .personslist--autoview .ut-person-tile__profilelink {
  display: none;
}