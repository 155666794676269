html
{
  /* Richtext content COLORS ───────────────────────────────────────────────────── */

  --rtd-heading-color:        var(--color-black);
  --rtd-intro-color:          var(--color-black);
  --rtd-text-color:           var(--color-black);

  --rtd-link-color:           var(--color-grey-dark);
  --rtd-link-color-hover:     currentColor;

/*  --rtd-link-font-weight:     normal;*/

  --rtd-list-ul-marker-color: inherit;
  --rtd-list-ol-marker-color: inherit;



  /* Richtext content FONTS ───────────────────────────────────────────────────── */

  --rtd-heading-fontfamily:  "Univers Next W02", Arial, Helvetica, sans-serif;
  --rtd-text-fontfamily:     "Univers Next W02", Arial, Helvetica, sans-serif;

  /* NOTE: For some reason the standard and condensed version of the Univers font
           are mashed into a single font definition. */
  --font-univers-std-family: "Univers Next W02";
  --font-univers-condensed-family: "Univers Next W02";
  --font-univers-std-normal: 400;

  --fontweight-univers-condensed-light:  300;
  --fontweight-univers-condensed-normal: 500;
  --fontweight-univers-condensed-bold:   700;

  --fontweight-univers-std-light:        200;
  --fontweight-univers-std-normal:       400;
  --fontweight-univers-std-bold:         600; /* Univers LT Std 65 Bold */

  /* Paragraphs */
  --rtd-intro-font:          300 26px/26px var(--rtd-text-fontfamily);
  --rtd-text-font:           15px/25px var(--rtd-text-fontfamily);
  --rtd-table-font:          15px/25px var(--rtd-text-fontfamily); /* FIXME */
  --rtd-text-subtle-font:    15px/25px var(--rtd-text-fontfamily); /* FIXME */
  --rtd-text-small-font:     14px/22px var(--rtd-text-fontfamily); /* FIXME */
  --rtd-text-tiny-font:      13px/20px var(--rtd-text-fontfamily); /* FIXME */

  /* Headings & intro */
  --rtd-h1-font:             700 58px/58px var(--rtd-heading-fontfamily);
  --rtd-h1-fontsize:         58px; /* for use in clamp() */
  --rtd-h1-font-header:      700 clamp(22px,4.9vw,58px)/100% var(--rtd-heading-fontfamily);
  --rtd-intro-font:          300 26px/26px var(--rtd-heading-fontfamily);
  --rtd-h2-font:             700 26px/30px var(--rtd-heading-fontfamily); /* was 26px/26px */
  --rtd-h2-scaling-font:     clamp(22px,1.8vw,26px)/100% var(--rtd-heading-fontfamily);
  --rtd-h3-font:             700 18px/20px var(--rtd-heading-fontfamily);
  --rtd-h4-font:             600 15px/25px var(--rtd-heading-fontfamily);

/*
  --rtd-table-heading-font:  17px/26px var(--rtd-heading-fontfamily);
*/

  --rtd-widget-heading-font:   700 18px/18px var(--rtd-heading-fontfamily);


  /* contentarea layout
  FIXME: not according to UT design yet!
  */

  --rtd-widget-heading-margin: 25px;

  --rtd-spacing-paragraph:                30px;
  --rtd-spacing-intro-after:              30px;
  --rtd-spacing-h2-after:                 15px;
  --rtd-spacing-h3-after:                 10px;
  --rtd-spacing-h4-after:                  5px;
  --rtd-spacing-widget-after:             32px;

  --rtd-spacing-widget-with-title-before: 65px;
/*  --rtd-spacing-widget-opaque-before:     65px;*/
/*  --rtd-spacing-widget-opaque-after:      65px;*/
  --rtd-spacing-widget-opaque-before:     32px;
  --rtd-spacing-widget-opaque-after:      32px;

/*  text-decoration-thickness: 2px;*/
  text-underline-offset: 3px;
}

@media (max-width: 480px)
{
  body
  {
    --rtd-h1-font:     700 40px/40px var(--rtd-heading-fontfamily);
    --rtd-h1-fontsize: 40px;
    --rtd-h2-font:     700 18px/20px var(--rtd-heading-fontfamily);
    --rtd-h3-font:     700 15px/25px var(--rtd-heading-fontfamily);
 /* --rtd-h4-font:     600 15px/25px var(--rtd-heading-fontfamily); */

    --rtd-intro-font:  300 18px/20px var(--rtd-text-fontfamily);
  }
}



@media (max-width: 780px)
{
  html
  {
    --contentpanels-radius-large:    8px;
    --contentpanels-radius-medium:   8px;
    --contentpanels-radius-small:    8px;
  }
}


@media (max-width: 600px)
{
  html
  {
    /* contentarea layout
    FIXME: not according to UT design yet!
    */

    --rtd-spacing-paragraph:                15px;
    --rtd-spacing-intro-after:              15px;
    --rtd-spacing-h2-after:                 15px;
    --rtd-spacing-widget-after:             32px;

    --rtd-spacing-widget-with-title-before: 32px;
    --rtd-spacing-widget-opaque-before:     32px;
    --rtd-spacing-widget-opaque-after:      32px;
  }

  .widget-button + .widget-button
  {
    margin-top: 15px;
  }
}
