.projectslist .icon {
  color: var(--overviewlist-icon-color);
}

.ut-project-tile__startdate {
  display: flex;
  align-items: center;
}

.ut-project-tile__startdate__finishedicon::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  content: "\e931";
  color: #3F9C35;
  font-size: 18px;
  margin-right: 5px;
}

.projectslist {
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content min-content min-content;
}

.ut-projects-listheader {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: last baseline;
}

.projectslist .ut-listheader__feedback {
  grid-column: 1/3;
}

.ut-project-tile {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  padding: 14px 10px;
  font: 13px var(--rtd-text-fontfamily);
  border-top: 1px solid #DCDDDE;
}

.ut-project-tile:last-child {
  border-bottom: 1px solid #DCDDDE;
}

.ut-project-tile__image,
.ut-project-tile__image-placeholder {
  flex: none;
  align-self: center;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: #F0F1F2;
  margin-right: 25px;
}

.ut-project-tile__meta {
  flex: 1 1 auto;
  display: flex;
  align-self: center;
}

.ut-project-tile__meta > * {
  align-self: last baseline;
  padding-right: min(60px, 10vw);
}

@media (max-width: 1250px) {
  .projectslist {
    grid-template-columns: min-content 1fr min-content min-content min-content;
  }
  .ut-project-tile__faculty {
    display: none;
  }
}
@media (max-width: 1050px) {
  .projectslist {
    grid-template-columns: min-content 1fr min-content min-content;
  }
  .ut-project-tile__researcharea {
    display: none;
  }
}
@media (max-width: 950px) {
  .projectslist {
    grid-template-columns: min-content 1fr;
  }
  .ut-project-tile__startdate,
  .ut-project-tile__duration {
    display: none;
  }
  .ut-project-tile__names {
    align-self: center;
  }
  .ut-project-tile__title {
    margin-bottom: 0px !important; /* instead of 5px */
  }
}
.ut-project-tile__meta,
.ut-project-tile__additional {
  display: contents;
}

.ut-project-tile__names {
  flex: 1 1 auto;
}

.ut-project-tile__title {
  color: #1E2328;
  font: 700 18px/24px var(--rtd-heading-fontfamily);
  text-wrap: pretty;
  text-transform: none;
  margin: 0 0 5px 0;
}

.ut-project-tile__teamlead {
  color: #1E2328;
  font: 15px/25px var(--rtd-heading-fontfamily);
}

.ut-project-tile__duration {
  white-space: nowrap;
}

.ut-project-tile__faculty {
  white-space: nowrap;
}