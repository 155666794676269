/* Low level components & generic layouting */
html
{
  --shared-icon-color:          #53575B;

  --itemslist-item-pad-left:    8px;
  --itemslist-item-pad-right:   8px;
  --itemslist-item-pad-top:     5px;
  --itemslist-item-pad-bottom:  5px;
  --itemslist-item-border-color: rgba(0,0,0,0.1);
  --itemslist-item-hover-background: #F0F1F2;

  --itemslist-image-size:      36px;
  --itemslist-border-radius:   10px;

  --itemslist-spacing:          8px;

/*  --largelisting-header-minheight:        60px;*/
/*  --largelisting-header-padding-h:        12px;*/
  --largelisting-header-padding-v:        10px;


  /* Generic settings for lists used in overviews */
  --overviewlist-header-color: #5A5E62;
  --overviewlist-icon-color:   #53575B; /* Persons overview page */
  --overviewlist-header-padding-bottom: 18px;
}

@media (max-width: 750px)
{
  html
  {
    --overviewlist-header-padding-bottom: 10px;
  }
}



html
{
  /* ADDME */
  --peoplepersonpage-tabs-marginbottom: 0px;
  --peoplepersonpage-feedbackbar-height: 46px; /* Used for spacing to header in PPP */


  --sharebar-icon-color:        var(--shared-icon-color);
  --sharebar-icon-size:         18px;


  /* Tagpanels (groeping for filters) */
  --tagpanel-heading-color:     var(--color-theme);
  --tagpanel-heading-font:      600 13px Arial; /* on Windows the bold Universe font at this size has the "i" looking like an "l" */
  --tagpanel-heading-margin:    5px;
  --tagpanel-option-pad-top:    4px;
  --tagpanel-option-pad-bottom: 4px;
  --tagpanel-option-spacing:    4px;
  --tagpanel-option-font:       14px/22px var(--rtd-heading-fontfamily);
  --filterpage-filtergroup-spacing:  20px;
}


html
{
  /* Tags in tile on eventsoverview */
  --component-tag-background:   #F0F1F2;
  --component-tag-text-color:   var(--rtd-text-color);
  --component-tag-border-color: transparent;
  --component-tag-font:         13px var(--rtd-text-fontfamily);
  --component-tag-height:       35px; /* var(--formcontrols-height); */
  --component-tag-radius:       3px;
  --component-tag-padding:      3px 16px 2px 16px;

  --component-tag-hover-background: var(--component-tag-background);
  --component-tag-hover-text-color: var(--rtd-text-color);

  --component-taglist-gap-h:    9px;
  --component-taglist-gap-v:    7px;
}

.peoplepersonpage-filters__filters__heading
{
  font: 13px var(--rtd-text-fontfamily);
  text-transform: none;

  border-bottom: 1px solid #DCDDDE;
  padding-bottom: var(--overviewlist-header-padding-bottom);

/*  margin-bottom: 14px;*/
  margin-bottom: 25px;
}

.peoplepersonpage-filters__filters__heading .icon
{
  font-size: 16px;
  line-height: 0;

  display: inline-block;
  width: calc(var(--formcontrol-checkradio-size) + 10px);

  position: relative;
  top: 3px;
}


/* FIXME */
@media (max-width: 800px)
{
  html
  {
    --component-tag-height:     35px;
/*    --component-tag-radius:     3px;*/
  }
}






html
{
  /************************************************************
   *
   *  Page components / Accordion style components (including FAQ widget)
   */

  --accordion-header-color:               #000000;
  --accordion-header-font:                var(--rtd-h3-font);
  --accordion-header-padding:             var(--largelisting-header-padding-v) 0;
  --accordion-header-minheight:           60px;

  /* 200 = lite, 400 = normal 700 = bold */
  --accordion-header-symbol-font:         300 22px 'UT-iconfont';
  --accordion-header-symbol-size:         22px;
/*  --accordion-header-symbol-color:        var(--color-theme);*/
  --accordion-header-symbol-color:        #AAACAE;
  --accordion-header-symbol-content:      "\e92f";
  --accordion-header-symbol-open-color:   var(--color-black);
  --accordion-header-symbol-open-content: "\e930";
  --accordion-header-symbol-shift-y:      7px;

  --accordion-header-hover-background:    #F5F5F5;
  --accordion-header-hover-symbol-color:  #231F20;

  --accordion-item-line-color:            var(--common-item-divider-line-color);

  --accordion-item-indent:                 0px;
  --accordion-item-symbolgutterwidth:     33px;


  /************************************************************
   *
   *  Page components / Autosuggest
   */

  --component-autosuggest-radius:     var(--contentpanels-radius);
  --component-autosuggest-padding:    13px 0 19px 0;
  --component-autosuggest-background: var(--contentpanels-background);
  --component-autosuggest-box-shadow: var(--contentpanels-box-shadow);

  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 4px;
  --component-autosuggest-item-font:  inherit; /* 19px */
  --component-autosuggest-item-color: #000000;


  /************************************************************
   *
   *  Page components / Dialogs
   *
   *  - sidebar (menu)
   *  - filters dialog
   */

  --dialog-closebutton-color: #53575B;
  --dialog-closebutton-size:  16px;


  /************************************************************
   *
   *  Page components / Tabstrip
   */

  --component-tabstrip-background:   transparent;
  --component-tabstrip-height:       39px;
  --component-tabstrip-spacing:      25px;
  --component-tabstrip-font:               13px var(--rtd-heading-fontfamily);
  --component-tabstrip-item-selected-font: 600 13px Arial; /* on Windows the bold Universe font at this size has the "i" looking like an "l" */

  --component-tabstrip-item-padtop:      5px;
  --component-tabstrip-item-padleft:    10px;
  --component-tabstrip-item-padright:   10px;
  --component-tabstrip-item-valign:     center;

  --component-tabstrip-item-color:      #767676;
  --component-tabstrip-item-background: transparent;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-color:          var(--color-black); /* var(--theme-color-cta); */
  --component-tabstrip-item-hover-background:     transparent;
  --component-tabstrip-item-hover-border:         none;

  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-color:       var(--color-black);

  --component-tabstrip-item-selected-underlining-color: var(--color-black);


  /************************************************************
   *
   *  Page components / Pagination
   */

  --pagination-prevnext-border:           1px solid #75BCD6;
  --pagination-prevnext-spacing:          10px;
  --pagination-prevnext-background-color: var(--theme-color); /* .widget-collapse .card .card-header background-color */
  --pagination-prevnext-icon-color:       #FFFFFF;

  --pagination-prevnext-hover-background: #FFFFFF;
  --pagination-prevnext-hover-border:     1px solid var(--theme-color);

  --pagination-prevnext-disabled-background: #EEEEEE;
  --pagination-prevnext-disabled-border:     1px solid transparent;
  --pagination-prevnext-disabled-icon-color: #666666;

  --pagination-font:                 15px/32px var(--rtd-text-fontfamily);
  --pagination-page-link-color:      var(--color-purple-dark);
  --pagination-page-current-color:   var(--color-purple-dark);
  --pagination-page-size:            54px;
  --pagination-page-radius:           7px;
  --pagination-page-spacing:          5px;

  --pagination-page-hover-background: #F7FBFE;

/* NEW compared to ZVI / SKW (fixme: backport) */
  --pagination-prevnext-hover-icon-color: var(--theme-color);
  /*
  --pagination-iconfont:              var(--fontawesome-solid);
  --pagination-previous-icon-content: var(--fa-arrow-left);
  --pagination-next-icon-content:     var(--fa-arrow-right);
  */


  --pagination-iconfont:              normal 15px 'UT-iconfont';
  --pagination-previous-icon-content: var(--uticon-arrow-left-16px);
  --pagination-next-icon-content:     var(--uticon-arrow-right-16px);




  /************************************************************
   *
   *  Filteredoverview / Teaser bar
   */

  --filteredoverview-feedbackbar-color:     var(--color-theme);
  --filteredoverview-feedbackbar-font:      var(--rtd-text-font);


  /* Teaserbar in page */
  --filteredoverview-teaserbar-background:       var(--theme-color-cta);
  --filteredoverview-teaserbar-background-hover: var(--theme-color-cta-hover);
  --filteredoverview-teaserbar-border:       0;
  --filteredoverview-teaserbar-borderradius: 0;
  --filteredoverview-teaserbar-color:        #FFFFFF;
  --filteredoverview-teaserbar-height:       64px;
}


/* Smaller checkboxes for lists with many options */
.peoplepersonpage-filters__filters
{
  /* Shared checkbox & radiobutton settings */
  --formcontrol-checkradio-size:                20px;
  --formcontrol-checkradio-borderradius:        2px;
/*    --formcontrol-check-font:    400 14px/24px var(--fontawesome);*/
  --formcontrol-check-font:    16px "UT-iconfont";
}

/*
.peoplepersonpage-filters__filters
.financielewegwijzer-topbar__query
*/
.peoplepersonpage-filters
{
  --component-autosuggest-radius:     var(--contentpanels-radius-small);
  --component-autosuggest-padding:    calc(var(--contentpanels-radius-small) + 11px) 0 4px 0;

  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 3px;
  --component-autosuggest-item-font:  inherit; /* 19px */
  --component-autosuggest-item-color: #000000;
}

.peoplepersonpage-filters .searchbar .wh-autocomplete-container
{
  top: calc(-1 * var(--contentpanels-radius-small) - 8px);
}

.peoplepersonpage-filters .searchbar__input
{
  position: relative;
  z-index: 12;
}



/* Large pill tabs used on the "Project pages"-homepage */
.pilltabs
{
  --component-tabstrip-background:   transparent;
  --component-tabstrip-height:       40px;
  --component-tabstrip-spacing:      15px; /* 11px in design, but we need a little more so there's still whitespace when on hovering the pilltab enlarges */
  --component-tabstrip-font:               700 18px var(--rtd-heading-fontfamily);
  --component-tabstrip-item-selected-font: 700 18px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padtop:     0;
  --component-tabstrip-item-padleft:    31px;
  --component-tabstrip-item-padright:   31px;
  --component-tabstrip-item-valign:     center;

  --component-tabstrip-item-color:      #002C5F;
  --component-tabstrip-item-background: #FFFFFF;
  --component-tabstrip-item-border:     1px solid #63B1E5;

  --component-tabstrip-item-hover-color:      #FFFFFF;
  --component-tabstrip-item-hover-background: #002C5F;
  --component-tabstrip-item-hover-border:     1px solid #002C5F;

  --component-tabstrip-item-selected-background: #002C5F;
  --component-tabstrip-item-selected-border:     1px solid #002C5F;
  --component-tabstrip-item-selected-color:      #FFFFFF;

  --component-tabstrip-item-selected-underlining-color: transparent;
}
.pilltabs [role="tab"]
{
  border-radius: 19px;
  text-transform: uppercase;
  transform: scale(1.0); /* signal intent to animate this */

  transition: transform 0.2s ease-out;
  outline-offset: 6px;
}
.pilltabs [role="tab"]:hover
{
  transform: scale(1.08);
}


html
{
  --color-theme: #F00;
}


  html
, .wh-form__button--cta /* button in CTA widget */
, .wh-form__button--next
, .wh-form__button--submit
{
  --button-font: 700 16px var(--rtd-heading-fontfamily);

  --button-background:            var(--theme-color-cta);
  --button-text-color:            #FFFFFF;
  --button-border:                0px;
  --button-icon-background:       #FFFFFF;
  --button-icon-color:            #FFFFFF;

  --button-hover-background:      var(--theme-color-cta-hover);
  --button-hover-text-color:      #FFFFFF;
  --button-hover-border:          0px;
  --button-hover-icon-background: #FFFFFF;
  --button-hover-icon-color:      #FFFFFF;

  --button-underline-color:       var(--color-purple-cta-background);

  --textbutton-color:             var(--color-purple1);
  --textbutton-hover-color:       #000000;
}

  .wh-form__button--cta /* button in CTA widget */
, .wh-form__button--next
, .wh-form__button--submit
{
  text-transform: uppercase;
}
/*
  .spc-button--outline
, .wh-form__button--outline
, .wh-form__buttongroup .wh-form__button--previous
{
  --button-background:            #FFFFFF;
  --button-text-color:            var(--color-purple1);
  --button-border:                2px solid var(--color-purple1);
  --button-icon-background:       var(--color-purple1);
  --button-icon-color:            #FFFFFF;

  --button-hover-background:      var(--color-purple-cta-background);
  --button-hover-text-color:      var(--color-purple-cta-textcolor);
  --button-hover-border:          2px solid var(--color-purple1);
  --button-hover-icon-background: #FFFFFF;
  --button-hover-icon-color:      var(--color-purple1);

  --button-underline-color:     var(--color-purple-cta-background);
}
*/


@media (max-width: 767px)
{
  html
  {
    --component-tabstrip-height: 39px; /* 48px; */

    --filteredoverview-feedbackbar-font: 16px var(--rtd-text-fontfamily);
  }
}


html
{
  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

  --component-navpath-link-color:        var(--color-purple2); /*var(--rtd-link-color);*/
  --component-navpath-color:             var(--color-purple2); /* var(--color-grey-text-small); */
  --component-navpath-font:              12px/16px var(--rtd-heading-fontfamily);
  --component-navpath-seperator-font:    14px/16px var(--rtd-heading-fontfamily);
/*  --component-navpath-seperator-content: "›";*/
  --component-navpath-seperator-content: "•";
}


/*
html
{
  --component-focus-animation: componentfocus 0s forwards;
}
*/
