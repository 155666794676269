.progress__item .foldablecontent__title {
  border-top: 1px solid #e0e0e1;
}

.foldablecontent__title {
  border-bottom: 1px solid #e0e0e1;
  font: 700 18px/20px var(--rtd-heading-fontfamily);
  cursor: pointer;
}

.foldablecontent__title:hover {
  background-color: var(--color-grey-lighter);
  border-bottom: 1px solid #000000;
}

.foldablecontent__title::after {
  margin-right: 30px;
  color: var(--accordion-header-symbol-color);
  content: var(--accordion-header-symbol-content);
  font: var(--accordion-header-symbol-font);
  font-size: 16px;
}

.foldablecontent__title:hover,
.foldablecontent__title:hover::after {
  color: var(--accordion-header-hover-symbol-color);
}

.progress__item[aria-expanded=true] .foldablecontent__title::after {
  color: var(--accordion-header-hover-symbol-open-color);
  content: var(--accordion-header-symbol-open-content);
}

.foldablecontent__foldcontainer {
  display: none;
}

.progress__item[aria-expanded=true] .foldablecontent__foldcontainer {
  display: block;
}

.foldablecontent__foldcontainer .foldablecontent__text {
  padding: 30px 0;
}