.page-sidebar__panel {
  --page-sidebar-font: 13px var(--rtd-text-fontfamily);
}

.page-sidebar__panel + .page-sidebar__panel {
  margin-top: 40px;
}

.page-sidebar__panel--specsheet.page-sidebar__panel--specsheet {
  margin-top: 12px;
}

.project__faculty {
  font: 700 18px/25px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.page-sidebar__panel h3 {
  font: var(--page-sidebar-font);
  margin-bottom: 8px;
}

.specsheet__item {
  display: flex;
  align-items: baseline;
}

.specsheet__item + .specsheet__item {
  margin-top: 8px;
}

.specsheet__item__icon {
  flex: none;
  display: block;
  margin-right: 18px;
  font-size: 18px;
  color: #767676;
}

.specsheet__item ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.specsheet__item a {
  color: inherit;
  text-decoration: none;
}

.specsheet__item a:hover {
  text-decoration: underline;
}