/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
@media (min-width: 768px) {
  html {
    --peoplepersonpage-topbar-margin: 40px;
  }
}
@media (max-width: 767px) {
  html {
    --peoplepersonpage-topbar-margin: 20px;
  }
}

.peoplepersonpage__content > .pagetabs {
  --component-tabstrip-item-valign: top;
}

.peoplepersonpage {
  --peoplepersonpage-filtertoggle-display: none;
  --peoplepersonpage-filters-display: grid;
}

.page--search .peoplepersonpage::before {
  content: "";
  grid-row: 6;
  grid-column: 1/4;
  border-top: 1px solid var(--color-purple3);
}
.page--search .peoplepersonpage-filters__filters {
  padding-top: 20px;
}

.peoplepersonpage-filters__filters {
  display: var(--peoplepersonpage-filters-display);
}

.notcurrentpage {
  display: none !important;
}

/*
.page--search .peoplepersonpage .pagetabs__content label:first-of-type::before
{
  content: var(--fa-search);
  font: var(--fontawesome-solid);
  font-size: 14px;

  vertical-align: center;

  margin-right: 19px;
  margin-bottom: 5px;
}
*/
.peoplepersonpage--withtopbar {
  margin-top: 0 !important;
}

.pageheader ~ .page__body .page__contentarea .peoplepersonpage--withtabsbar {
  margin-top: 0 !important; /* always stick to the header */
}

.peoplepersonpage__topbar {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.peoplepersonpage__backlink {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-theme);
  font: var(--rtd-text-font);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: none;
}
html.page--publicationsoverview-hidelanding .peoplepersonpage__backlink {
  display: block;
}

.peoplepersonpage__backlink:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.peoplepersonpage__backlink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-left);
  margin-right: 20px;
}

.page--publicationsoverview .pageheader__text {
  grid-column: var(--pagegrid-col-verywide) !important;
}

.peoplepersonpage-filters > .peoplepersonpage-filters__header {
  align-self: center;
}

.peoplepersonpage-filters__filters > .peoplepersonpage-filters__header .peoplepersonpage-filters__filters__title {
  margin-top: 20px;
}

.peoplepersonpage-filters__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/* we only show the header reset button when using in the dialog.
   When used within the normal .page__body / .peoplepersonpage whe don't need it.
*/
.page__body .peoplepersonpage-filters__header .filteredoverview-resetbutton {
  display: none;
}

.peoplepersonpage-filters__topbar {
  display: none;
}

.peoplepersonpage-filters__topbar label {
  color: var(--color-theme);
  font-size: 17px;
  margin-right: 12px;
}

.peoplepersonpage-filters {
  margin: 0;
  margin-top: 0 !important;
}

.peoplepersonpage__results .searchresult.notmatching {
  display: none;
}

.peoplepersonpage__results + * {
  margin-top: 23px;
}

html {
  --peoplepersonpage-sidepadding: var(--pagegrid-current-sidepadding);
  --peoplepersonpage-whitespace-left: var(--pagegrid-current-sidegutter);
  --peoplepersonpage-whitespace-right: var(--pagegrid-current-sidegutter);
  /*
    @media (min-width: 1200px)
    {
      --peoplepersonpage-whitespace-right: min(7%, 295px);
    }
  */
}
@media (max-width: 768px) {
  html {
    --peoplepersonpage-whitespace-left: var(--pagegrid-current-sidegutter);
    --peoplepersonpage-whitespace-right: var(--pagegrid-current-sidegutter);
  }
}

.peoplepersonpage {
  --pagegrid-filterpage-template-columns:
                   minmax(0, var(--peoplepersonpage-sidepadding))
                   var(--peoplepersonpage-whitespace-left)
                   var(--peoplepersonpage-sidebar-columndef) /* sidebar */
                   52px /* gutter */
                   minmax(0, 3fr) /* content - use minmax(0, ..) so the layout doesn't change when content gets visible which may stretch the container */
                   var(--peoplepersonpage-whitespace-right)
                   minmax(0, var(--peoplepersonpage-sidepadding));
}

.page--project {
  /*  --peoplepersonpage-sidebar-preferredwidth: 286px;*/
  --filterpage-filtergroup-padding: 15px 12px;
  /*  --peoplepersonpage-sidebar-columndef: minmax(min-content, min(30%, 300px));*/
  /* don't stretch to min-content here - our multiselects will stretch with the selected option */
  --textcontrols-font: 16px var(--rtd-text-fontfamily);
  --textcontrols_textcolor: #333333;
}
@media (max-width: 1099px) {
  .page--project {
    --peoplepersonpage-whitespace-left: 15px;
    --peoplepersonpage-whitespace-right: 15px;
  }
}
@media (min-width: 1100px) {
  .page--project {
    --peoplepersonpage-whitespace-left: 77px;
    --peoplepersonpage-whitespace-right: 77px;
  }
}

/********************************************************
**
**  Desktop generic page layout
*/
@media (min-width: 768px) {
  .peoplepersonpage {
    --peoplepersonpage-sidebar-preferredwidth: 250px;
    --peoplepersonpage-sidebar-columndef: minmax(min-content, min(30%, var(--peoplepersonpage-sidebar-preferredwidth) ));
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-filterpage-template-columns);
    grid-template-rows: min-content min-content min-content min-content var(--peoplepersonpage-tabs-marginbottom) minmax(var(--peoplepersonpage-feedbackbar-height), min-content) 1fr; /* results */
    align-items: start;
    --pagegrid-col-withsidebar-sidebar: 3;
    --pagegrid-col-withsidebar-content: 5;
    --pagegrid-col-withsidebar-tabs: 5 / span 2;
    --pagegrid-col-withsidebar-verywide-outsidegutter: 2 / 7;
  }
  .peoplepersonpage--shrinkwrap-sidebar {
    --peoplepersonpage-sidebar-columndef:
              minmax( min( 100px, var(--peoplepersonpage-sidebar-preferredwidth) )
                    , max-content
                    );
  }
  .peoplepersonpage--shrinkwrap-sidebar .peoplepersonpage-filters__filters {
    /* FIXME: have code set a CSS variable with the available verywide contentside */
    /* We use a max-width here because we cannot mix "max-content" (to shrinkwrap)
       with another maximum (% of available content width) in a column definition; */
    max-width: max(0.2 * min(100vw, 1150px), 260px);
  }
  .peoplepersonpage-filters__filters {
    position: sticky;
    top: 130px;
  }
  .peoplepersonpage {
    /*
    .header-crumblepath__background
    {
      grid-row: 1 / span 2;
      grid-column: 1 / -1;
      border-bottom: 1px solid #F00;
    }
    */
    /* Page-header nested in the filterpage layout */
  }
  .peoplepersonpage .header-crumblepath {
    display: contents;
  }
  .peoplepersonpage .header-crumblepath-hr {
    grid-row: 1;
    grid-column: 1/8;
    width: 100%;
    align-self: end;
    margin: 0;
    height: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid var(--color-purple3);
  }
  .peoplepersonpage .header-crumblepath__content {
    grid-row: 1;
    grid-column: var(--pagegrid-col-withsidebar-content);
    height: 55px;
  }
  .peoplepersonpage .pageheader {
    grid-row: 2;
  }
  .peoplepersonpage .pagetabs {
    display: contents;
  }
  .peoplepersonpage .pagetabs__background {
    grid-row: 4;
    grid-column: 1/-1;
    border-bottom: 1px solid var(--color-purple3);
    height: 100%;
  }
  .peoplepersonpage .pagetabs--tabs.pagetabs--tabs .pagetabs__content {
    grid-row: 4;
    grid-column: var(--pagegrid-col-withsidebar-tabs);
  }
  .peoplepersonpage .peoplepersonpage-custombar {
    display: contents;
  }
  .peoplepersonpage .peoplepersonpage-custombar > * {
    grid-row: 4;
  }
  .peoplepersonpage .peoplepersonpage-filters > .peoplepersonpage-filters__header {
    grid-column: var(--pagegrid-col-withsidebar-sidebar);
    grid-row: 6;
  }
  .peoplepersonpage .peoplepersonpage-filters__filters {
    grid-column: var(--pagegrid-col-withsidebar-sidebar);
    grid-row: 7;
    align-self: start;
  }
  .peoplepersonpage .peoplepersonpage-filters__filters--includeheader {
    grid-row: 4/span 2;
  }
  .peoplepersonpage .peoplepersonpage__content {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 7;
    /* prevent page stretching and flex layouts stretching */
  }
  .peoplepersonpage-filters {
    display: contents; /* we want to layout the .pagetabs, __header, __filters and __topbar withinin our grid */
  }
  .peoplepersonpage-filters > .peoplepersonpage-filters__header,
  .peoplepersonpage-filters__topbar {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .page--search .peoplepersonpage__content {
    padding-top: 20px;
  }
  /**************/
  .peoplepersonpage-filters__topbar {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 5;
    display: flex;
    align-items: baseline;
    margin-bottom: var(--peoplepersonpage-topbar-margin);
  }
  .peoplepersonpage-filters__topbar > .filteredoverview__feedbackandviewbar {
    flex: 1 1 auto;
  }
  /*
    .peoplepersonpage-filters__topbar > :first-child:last-child
    {
      margin-left: auto;
    }
    .peoplepersonpage-filters__topbar > .filteredoverview__feedbackandviewbar + *
    {
      margin-left: 20px;
    }
  */
  .filteredoverview__feedback + .filtertags__items {
    margin-top: 20px;
  }
  .peoplepersonpage-custombar > * {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .peoplepersonpage-custombar > hr {
    grid-column: 1/-1;
    width: 100%;
    margin: 0;
    align-self: end;
    border: 0;
    border-bottom: 1px solid var(--color-purple3);
  }
}
/* Make tabs go full viewport width earlyer */
@media (max-width: 1000px) {
  /* FIXME: autodetect tabsbar not fitting right part and going fullw dith */
  .pagetabs--tabs.pagetabs--tabs {
    grid-column: 3/-3 !important;
  }
  .pagetabs--tabs.pagetabs--tabs .pagetabs__background,
  .pagetabs--tabs.pagetabs--tabs .pagetabs__content {
    grid-column: 1/-1 !important;
  }
  /* NOTE: don't target a next subling (+) because in mobile some elements will be hidden */
  .page--newsoverview2 .peoplepersonpage-filters__topbar {
    margin-top: 15px;
  }
}
/* Remove the empty space at the right */
@media (max-width: 1300px) {
  .peoplepersonpage {
    --peoplepersonpage-emptyness-columndef: 0px;
  }
}
/********************************************************
**
**  Mobile (and small tablets) layout
*/
@media (max-width: 767px) {
  .peoplepersonpage {
    --peoplepersonpage-emptyness-columndef: 0;
  }
  .peoplepersonpage .header-crumblepath {
    display: contents;
  }
  .peoplepersonpage .header-crumblepath__content {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
    height: 55px;
  }
  .peoplepersonpage .header-crumblepath__content {
    grid-column: var(--pagegrid-col-verywide) !important;
  }
  .peoplepersonpage-filters {
    display: contents;
    --formcontrols-height: 40px;
  }
  .peoplepersonpage-filters > .pageheader {
    grid-row: 2;
  }
  .peoplepersonpage-filters__topbar {
    margin-bottom: var(--peoplepersonpage-topbar-margin);
  }
  .peoplepersonpage-filters__filters,
  .peoplepersonpage-filters__topbar {
    grid-column: var(--pagegrid-col-verywide);
  }
  .filteredoverview__feedback i,
  .filteredoverview__feedbackandviewbar__content .filtertags__items {
    display: none;
  }
  .peoplepersonpage-filters__filters {
    --component-tag-height: var(--formcontrols-height);
    --component-tag-padding: 4px 12px;
    --component-tag-font: 15px/22px var(--rtd-text-fontfamily);
  }
  .peoplepersonpage-filters__filters .comp-togglebuttonbar {
    column-gap: 10px;
  }
  .peoplepersonpage__results.peoplepersonpage__results {
    --component-summary-padding-v: 20px;
  }
  .peoplepersonpage-custombar {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 4;
    display: flex;
    flex-direction: column;
  }
  .financielewegwijzer-topbar__query {
    width: 100%;
  }
  .financielewegwijzer-topbar__resultdata.financielewegwijzer-topbar__resultdata {
    margin-top: 15px;
    display: flex;
    width: 100%;
  }
  .filteredoverview__feedback {
    flex: 1 0 0;
    min-width: 0;
  }
  .downloadresultsbutton {
    margin-left: 30px;
  }
  .peoplepersonpage__content {
    grid-column: var(--pagegrid-col-withsidebar-content);
    grid-row: 7;
    /* prevent page stretching and flex layouts stretching */
  }
}
@media (min-width: 768px) {
  .peoplepersonpage__content > .pagetabs ~ .tabpanel {
    margin-top: 48px;
  }
}
@media (max-width: 767px) {
  .peoplepersonpage-filters__filters,
  .peoplepersonpage__content {
    margin-top: 45px;
  }
  .peoplepersonpage__content > .tabpanel + .tabpanel {
    padding-top: 48px;
  }
  .peoplepersonpage {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-crumblepath-hr {
    display: none;
  }
  .peoplepersonpage-filters__topbar,
  .peoplepersonpage-filters > .peoplepersonpage-filters__header,
  .peoplepersonpage__content > * {
    grid-column: var(--pagegrid-col-verywide);
  }
  .peoplepersonpage-filters__filters {
    grid-row: 6;
  }
  .page--person .peoplepersonpage-filters__filters {
    display: none;
  }
  .peoplepersonpage-filters__filters > * {
    grid-column: var(--pagegrid-col-verywide);
  }
  .peoplepersonpage-filters__filters,
  .peoplepersonpage__content {
    grid-column: var(--pagegrid-col-fullwidth);
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .peoplepersonpage__content {
    display: grid;
  }
  .peoplepersonpage-filters__filters .page-sidebar__panel,
  .peoplepersonpage__content > * {
    grid-column: var(--pagegrid-col-verywide);
  }
  /*****************************************/
  .peoplepersonpage.peoplepersonpage--hidefilters-on-mobile {
    --peoplepersonpage-filtertoggle-display: initial;
    --peoplepersonpage-filters-display: none;
  }
  .peoplepersonpage.peoplepersonpage--hidefilters-on-mobile .pagetabs,
  .peoplepersonpage.peoplepersonpage--hidefilters-on-mobile > form > .peoplepersonpage-filters__filters {
    position: absolute; /* take out of flow so a row-gap won't be applied */
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
  .peoplepersonpage__results.peoplepersonpage__results {
    --component-summary-padding-v: 13px;
  }
}
.filteredoverview-resetbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #767676;
  font: var(--rtd-text-small-font);
  text-decoration: underline;
  cursor: pointer;
}