.page--projectpageshome {
  --pagegrid-col-default: var(--pagegrid-col-verywide);
  --pageheader-margin-to-content: 55px;
}
.page--projectpageshome .page__contentarea--rtddoc > p {
  padding-left: 30px;
  padding-right: 23px;
}
.page--projectpageshome .page__contentarea--rtddoc .pagetabs__content {
  padding-left: 23px;
  padding-right: 23px;
}
.page--projectpageshome .pagetabs {
  margin-top: 10px;
}
.page--projectpageshome .spc-tabpanel {
  padding-top: 52px;
}
.page--projectpageshome .widget-cta + .widget-cta {
  --rtd-spacing-widget-after: 150px;
}
@media (max-width: 750px) {
  .page--projectpageshome .widget-cta + .widget-cta {
    --rtd-spacing-widget-after: 0px;
  }
}