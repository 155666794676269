/*
Include stylesheets here which need access to the
settings for the current webdesign.
*/
.spc-tabpanel {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.spc-tabpanel[hidden] {
  display: none;
}

.header-crumblepath {
  grid-column: var(--pagegrid-col-fullwidth);
  min-height: 55px;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: 1fr min-content; /* crumblepath row and divider row */
  align-items: center;
}
@media print {
  .header-crumblepath {
    height: auto;
  }
}

.header-crumblepath__content {
  grid-column: var(--pagegrid-col-default);
  padding: 10px 0;
}

/* FIXME: Chrome, Edge, Safari 15.4 (mar 2022)... Firefox is working on it
   https://caniuse.com/css-has
*/
body:has(> header) .header-crumblepath__content {
  grid-column: var(--pagegrid-col-verywide-outsidegutter-text);
}

.header-crumblepath {
  border-bottom: 1px solid var(--color-purple3);
}

@media (max-width: 500px) {
  .header-crumblepath {
    --component-navpath-font: 10px/16px var(--rtd-heading-fontfamily);
    --component-navpath-seperator-font: 12px/16px var(--rtd-heading-fontfamily);
    min-height: 42px;
  }
}
.peoplepersonpage-filters__filters__title {
  font: 500 18px/24px var(--rtd-heading-fontfamily);
}