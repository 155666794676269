.skiplinksbutton__wrapper
{
  position: sticky;
  left:    15px;
  top:     26px;
  z-index: 1000;


  grid-row: 1;
/*  grid-column: var(--pagegrid-col-verywide);*/
  grid-column: var(--site-menubar-grid-column);
  justify-self: start;

  contain: size;
}

button.skiplinksbutton
{
  border: 0;
  appearance: none;

  /*position: absolute;*/
  align-self: top;
  flex: none;

  z-index: 100;

  display: flex;
  align-items: center;

  white-space: nowrap;

/*
  / * decorative * /
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 16px;
*/

  /* be hidden, but be available for focus by keyboard */
  opacity: 0; /* visibility: hidden; would remove it from the tab navigation */
  pointer-events: none;
}

  button.skiplinksbutton:focus
/*, button.skiplinks:active*/
, button.skiplinksbutton:hover /* in Safari the button shortly loses focus upon click, so prevent it from disappearing before the click lands */
{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

body:has(button.skiplinksbutton:focus) .header-menubar__identity
{
  opacity: 0;
}
