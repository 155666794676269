#showdesigntools
{
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  align-self: start;
  justify-self: start;
  z-index: 10000;

/*  appearance: none;*/
  width: 35px;
  height: 35px;
  border: 1px solid #000000;

  opacity: 0;
  transition: opacity 0.2;

  position: sticky;
  top: 10px;
  left: 10px;

  cursor: pointer;
}

#showdesigntools:hover
{
  opacity: 1;
}




.debugsettings
{
  display: none;
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  max-width: 160px;

  align-self: start; /* schrink-wrap vertically */
  justify-self: start;

  position: sticky;
  top: 90px;
  left: 8px;
  z-index: 99999;

  opacity: 0.8;
  transition: opacity 0.2;
}

.debugsettings:hover
{
  opacity: 1;
}



.debugpanel--customcontent
{
  display: none;
}

  html.enable-designtools .debugsettings
, html.enable-designtools .debugpanel--customcontent
{
  display: block;
}

.spc-designoverlay__info
{
  position: absolute;
  top: 8px;

  max-width: max-content;
  background-color: rgba(255,255,255,0.9) !important;
}

  .debugsettings__panel
, .spc-designoverlay__info
{
  background-color: #EEFFFF;
  border: 1px solid #88AAAA;
  padding: 15px;

  font: 15px var(--rtd-text-fontfamily);

  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

.debugsettings__panel + .debugsettings__panel
{
  margin-top: 15px;
}

.debugsettings__panel__heading
{
  display: flex;
  margin-bottom: 10px;
}

.debugsettings__panel__heading input
{
  margin: 0 10px 0 0;
}


.debugsettings__item
{
  padding: 4px 0;
}

.debugsettings label
{
  cursor: pointer;
  font-size: 13px;

  display: flex;
  align-items: baseline;
}

.debugsettings label input
{
  margin: 0;
  margin-right: 10px;

  position: relative;
  top: 2px;
}


.debugpanel
{
  background-color: rgba(255,128,0, 0.6);
  padding: 15px;
  border: 1px solid #AA8800;

  border-collapse: collapse;

  font: 13px/16px Arial;

  z-index: 100;
}

