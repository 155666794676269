.ut-project-tile-list
{
  --projecttiles-spacing-h: 30px;
  --projecttiles-spacing-v: 25px;
  --projecttiles-title-margin: 4px 0 5px 0;
  --projecttiles-image-maxwidth: 255px;
}

.ut-project-tile-list.gridview
{
  --projecttiles-title-margin: 14px 0 3px 0;
}



/****************************************************************************
 *
 *  Shared styling
*/

.ut-projecttile__image-placeholder
{
  background: #F0F1F2;
}

.ut-projecttile__abovetitle
{
  color: #5A5E62;
  font: var(--font-univers-std-normal) 13px/25px var(--font-univers-std-family);
}

.ut-projecttile__title
{
  font: var(--rtd-h3-font);
  line-height: 25px;

  text-transform: none;
  margin: var(--projecttiles-title-margin);
}

.ut-projecttile__description
{
  color: #5A5E62;
  font: var(--rtd-text-font);

  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box; /* ONLY works with -webkit-box */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

a.ut-projecttile__readmore
{
  display: inline-block;
  margin-top: 10px;
  color: #5A5E62;
}

.ut-projecttile__tags
{
  margin-top: 8px;
  color: #5A5E62;

  text-wrap: pretty;
  // text-wrap: balance;
}

.ut-projecttile__tags__item
{
  white-space: nowrap;
}

  .ut-projecttile__abovetitle .divider
, .ut-projecttile__tags .divider
{
  margin-left: 5px;
  margin-right: 5px;
}



/****************************************************************************
 *
 *  Grid view
*/

.ut-project-tile-list.gridview .ut-project-tile-list__items
{
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(255px, 1fr) );
  grid-auto-flow: dense;

  column-gap:  var(--projecttiles-spacing-h);
  row-gap:     var(--projecttiles-spacing-v);
}

.ut-project-tile-list.gridview .ut-projecttile__image
{
  display: block; /* fully control the whitespace - prevent line-height adding whitespace below the image */
  width: 100%;
}

.ut-project-tile-list.gridview .ut-projecttile__description
{
  -webkit-line-clamp: 5;
  font: var(--font-univers-std-normal) 13px/25px var(--font-univers-std-family);
}



/****************************************************************************
 *
 *  List view
*/

.ut-project-tile-list.listview .ut-projecttile + .ut-projecttile
{
  margin-top: var(--projecttiles-spacing-v);
}

@media (min-width: 450px)
{
  .ut-project-tile-list.listview .ut-projecttile
  {
    display: flex;
  }

    .ut-project-tile-list.listview .ut-projecttile__image
  , .ut-project-tile-list.listview .ut-projecttile__image-placeholder
  {
    align-self: start; /* don't stretch with the text - always keep aspect ratio */
    max-width: var(--projecttiles-image-maxwidth);
    height: auto;
    margin-right: var(--projecttiles-spacing-h);
  }
}

@media (max-width: 449px)
{
}

  .ut-projecttile__image
, .ut-projecttile__image-placeholder
{
  flex: none;
  width: 100%;
  /* height: 143px; */
  border-radius: 10px;
}

.ut-projecttile__meta
{
  flex: 1 0 0px;
}



/****************************************************************************
 *
 *  List view + large
*/

  .ut-project-tile-list.listview-large .ut-projecttile__image
, .ut-project-tile-list.listview-large .ut-projecttile__image-placeholder
{
  flex: 1 0 0px;
  max-width: none; /* just use half of the available space regardless of how much it is */
}

.ut-project-tile-list.listview-large h3
{
  margin-top: 18px;
  font: var(--rtd-h2-font);
  text-transform: uppercase;
  margin-bottom: 12px;
}

.ut-project-tile-list.listview-large .ut-projecttile__description
{
  color: #1E2328;
  -webkit-line-clamp: 10; // we have a lot more room next to the now very large image
}
