.spcgridoverlay__measurements
{
  display: none;

  position: sticky;
  top: 0;
  grid-row: 1 / span 2;

  grid-column: 1 / -1;
  overflow: hidden; /* never influence the width of the page */

  z-index: 100;
  pointer-events: none;
}

.spcdesigner-measurements--enable .spcgridoverlay__measurements
{
  display: block;
}

.spcgridoverlay__measurements > *
{
/*  align-self: center;*/
  margin-left: auto;
  margin-right: auto;
  min-height: 2px;

  background: #F00;
  color: #FFF;

  font-size: 12px;
}

.spcgridoverlay__measurements__item
{
  margin-top: 4px;
  text-align: center;
}
