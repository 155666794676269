/*

Each progress__item can have
- (default state) greyed out
- .failed
- .passed


NOTES on progress widget:

- uses two shades of grey and a green and red which aren't in the styleguide
- the collapse icon is dark when the foldable content is opened


https://ut2013.kodos.sf.webhare.nl/temp/admission/?step=osiris

*/
/*
2023
- switched to using CSS variables (also in preperation of usage in utwente_ppp)

Future
- switch to using container queries
*/
.embeddedobject--progress {
  --widget-progress-color-done: #3F9C35;
  --widget-progress-color-failed: #CD202C;
  --widget-progress-timeline-shiftx: 0px;
  --widget-progress-timeline-color-inactive: var(--color-grey-lines);
  --widget-progress-title-margin: 26px;
  --widget-progress-header-height: 70px;
  --widget-progress-indicator-size: 25px;
  --widget-progress-indicator-fontsize: 12px;
  --widget-progress-text-indent: 20px;
  --widget-progress-header-inactive-color: #9A9C9E;
  --widget-progress-header-inactive-color-hover: #484B4F;
}
@media (max-width: 480px) {
  .embeddedobject--progress {
    --widget-progress-title-margin: 20px;
    --widget-progress-header-height: 50px;
    --widget-progress-indicator-size: 20px;
    --widget-progress-indicator-fontsize: 11px;
    --widget-progress-text-indent: 10px;
  }
}
@media (max-width: 480px) {
  .embeddedobject--progress {
    padding-left: 20px !important;
  }
}

.embeddedobject--progress .progress__item + .progress__item {
  margin-top: -1px;
}

.embeddedobject--progress h2,
.embeddedobject--progress .foldablecontent__title,
.embeddedobject--progress .foldablecontent__foldcontainer {
  padding-left: calc(var(--widget-progress-text-indent) + var(--widget-progress-indicator-size) * 0.5);
}

.embeddedobject--progress.embeddedobject--progress h2 {
  position: relative;
  margin-top: 0;
  margin-bottom: var(--widget-progress-title-margin);
}

.embeddedobject--progress h2::before {
  position: absolute;
  z-index: 1;
  left: calc(var(--widget-progress-timeline-shiftx) - 3px);
  top: 50%;
  margin-top: 0 !important;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid var(--widget-progress-color-done);
  background-color: var(--color-white);
  content: "";
  color: var(--widget-progress-color-done);
}

.embeddedobject--progress .foldablecontent__title {
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: var(--widget-progress-header-height);
  display: flex;
  align-items: center;
  padding-right: 0;
}

.embeddedobject--progress .foldablecontent__title:hover::before {
  margin-left: 0 !important;
  width: calc(100% - 20px) !important;
}

.embeddedobject--progress .foldablecontent__title::after {
  position: static;
  margin-left: auto;
}
@media (max-width: 480px) {
  .embeddedobject--progress .foldablecontent__title::after {
    font-size: 12px;
    padding-top: 19px;
  }
}

@media (min-width: 481px) {
  .progress__item--withicon .foldablecontent__title::after {
    margin-left: 15px;
  }
}

.titlesuffix-conditional,
.titlesuffix-failed,
.titlesuffix-success {
  display: none;
}

.progress__item--conditional .titlesuffix-conditional,
.progress__item--failed .titlesuffix-failed,
.progress__item--passed .titlesuffix-success {
  display: inline;
}

.progress__item__icon {
  width: 48px;
  height: 48px;
  font-size: 48px;
  line-height: 48px;
}

.embeddedobject--progress .foldablecontent__title .progress__item__icon {
  margin-left: auto;
}
@media (max-width: 480px) {
  .embeddedobject--progress .foldablecontent__title .progress__item__icon {
    display: none;
  }
}

.embeddedobject--progress .foldablecontent__foldcontainer .progress__item__icon + * {
  margin-top: 10px;
}

@media (min-width: 481px) {
  .embeddedobject--progress .foldablecontent__foldcontainer .progress__item__icon {
    display: none;
  }
  .embeddedobject--progress .foldablecontent__foldcontainer .progress__item__icon + * {
    margin-top: 0;
  }
}
.progress__item {
  color: var(--widget-progress-header-inactive-color);
}

.progress__item:hover {
  color: var(--widget-progress-header-inactive-color-hover);
}

.progress__item.progress__item--conditional,
.progress__item.progress__item--failed,
.progress__item.progress__item--passed {
  color: var(--color-black);
}

.progress__item .foldablecontent__title {
  color: inherit;
  z-index: auto;
}
@media (max-width: 480px) {
  .progress__item .foldablecontent__title {
    font-size: 15px;
  }
}

.progress__item .foldablecontent__title::before {
  z-index: 0;
  margin-left: -30px;
}

.progress__item .foldablecontent__title:hover::before {
  width: calc(100% + 10px);
}

.progress__item .foldablecontent__title:after {
  position: relative;
}

.progress__item.foldablecontent--open .foldablecontent__title:after {
  color: inherit;
}

.progress__item .foldablecontent__title .subtitle {
  font-weight: 300;
}

.progress__item .foldablecontent__foldcontainer {
  position: relative;
}

.progress__item .foldablecontent__foldcontainer::before {
  position: absolute;
  left: var(--widget-progress-timeline-shiftx);
  top: calc(var(--widget-progress-header-height) * -0.5);
  bottom: 0;
  border-left: 1px solid var(--widget-progress-timeline-color-inactive);
  content: "";
}

.progress__indicator {
  position: absolute !important;
  left: var(--widget-progress-timeline-shiftx);
}

.progress__indicator::before {
  position: absolute;
  top: calc(-1 * var(--widget-progress-header-height));
  height: var(--widget-progress-header-height);
  border-left: 1px solid var(--widget-progress-timeline-color-inactive);
  display: block;
  content: "";
}

h2 + .progress__item .progress__indicator::before,
.progress__item--passed .progress__indicator::before,
.progress__item--passed:not(.progress__item--currentitem) + .progress__item .progress__indicator::before,
.progress__item--passed:not(.progress__item--currentitem) .foldablecontent__foldcontainer::before {
  border-left: 1px solid var(--widget-progress-color-done);
}

/* The circle in front of each step */
.progress__indicator::after {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 1px solid var(--widget-progress-timeline-color-inactive);
  text-align: center;
  top: 50%;
  width: var(--widget-progress-indicator-size);
  height: var(--widget-progress-indicator-size);
  line-height: var(--widget-progress-indicator-size);
  margin-top: calc(var(--widget-progress-indicator-size) * -0.5);
  left: calc(var(--widget-progress-indicator-size) * -0.5);
  font-size: var(--widget-progress-indicator-fontsize);
  display: block;
  content: "";
}

.progress__item--passed .progress__indicator::after,
.progress__item--conditional .progress__indicator::after {
  border-color: var(--widget-progress-color-done);
  color: var(--widget-progress-color-done);
  content: "\e9d5";
}

.progress__item--failed .progress__indicator::after {
  color: var(--widget-progress-color-failed);
  border-color: var(--widget-progress-color-failed);
  content: "\e92e";
}

.progress__item .foldablecontent__text {
  overflow-wrap: break-word;
}

.progress__item--optional .progress__indicator::after {
  border-style: dashed;
}

.progress__item--optional.progress__item--passed:not(.progress__item--active) {
  border-color: var(--widget-progress-header-inactive-color);
  color: var(--widget-progress-header-inactive-color);
}

.progress__item--optional.progress__item--passed:not(.progress__item--active) .progress__indicator::after {
  border-color: var(--widget-progress-header-inactive-color);
  color: var(--widget-progress-header-inactive-color);
  content: "\e92e";
}

.progress__item p.statusheading {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
}