.page--test-pageheaders h2 {
  margin-top: 50px;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  margin-bottom: 10px;
}

.pageheadertest__header-wrapper {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.debug_headersettings_wrapper {
  position: relative;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.debug_headersettings {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 1;
  min-width: 200px;
  width: max-content;
}

.debug_headersettings__table th {
  text-align: left;
  font-weight: bold;
  padding-right: 20px;
}

.debug_headersettings__hdr_traits {
  font-weight: bold;
}

.debug_headersettings__table ul {
  margin: 0;
  padding: 0 0 0 19px;
}