/*
ws2021 - .contentpart__topmenuitem .contentpart__topsubmenu
ppp    - .header-menubar__submenu
*/
.header-menubar__menuitem + .header-menubar__dropdownmenu {
  position: absolute;
  right: 0;
  top: var(--navbar-height);
  background: var(--color-white);
  border: 1px solid #DDDDDD; /* PPP */
  box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.3019607843);
  color: var(--color-black);
  padding: var(--navbar-menu-padding) 0;
  text-align: left;
  transition: opacity var(--menu-transition-duration);
  min-width: var(--navbar-menu-width);
  z-index: var(--zindex-popup);
  display: none;
}

.header-menubar__dropdownmenu hr {
  margin: 10px calc(var(--navbar-menu-item-padding) + 5px);
  height: 0;
  border: 0;
  border-top: 1px solid var(--navbar-menu-divider-color);
}

.header-menubar__dropdownmenu:not([hidden]) {
  display: flex;
  flex-direction: column;
}

.header-menubar__dropdownmenu::after {
  background: var(--navbar-menu-background);
  border-left: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
  bottom: 100%;
  content: "";
  height: 15px;
  transform: translateY(7px) rotate(45deg);
  width: 15px;
  position: absolute;
}

#header-menubar__accountmenu::after {
  right: 81px;
}

#header-menubar__languagemenu::after {
  right: 21px;
}

.header-menubar__dropdownmenu::before {
  background: transparent;
  content: "";
  height: 12px;
  position: absolute;
  top: -12px;
  width: calc(var(--navbar-menubutton-width) + 11px);
}

#header-menubar__accountmenu::before {
  right: 53px;
}

#header-menubar__languagemenu::before {
  right: 0;
  width: calc(var(--navbar-menubutton-width) + 5px);
}

/*
ws2021 - .contentpart__topsubitem
ppp    - .dropdownmenu__item
*/
button.dropdownmenu__item {
  width: -webkit-fill-available;
  width: fill-available;
  align-self: stretch;
  border: 0;
  background: none;
  text-align: left;
}

.dropdownmenu__item {
  color: var(--color-black);
  display: block;
  flex: none;
  font: var(--rtd-text-font);
  font-weight: var(--weight-normal-condensed);
  line-height: 100%;
  padding: calc((var(--navbar-menu-item-height) - 15px) / 2) var(--navbar-menu-item-padding);
  position: relative;
  text-decoration: none;
  margin: 0 10px;
}

a.dropdownmenu__item,
button.dropdownmenu__item {
  cursor: pointer;
  color: var(--color-grey-dark);
}

/*
ws2021: .contentpart__topsubitem--current
ppp:    .dropdownmenu__item--selected
*/
.dropdownmenu__item--selected {
  color: var(--color-black);
  font-weight: var(--weight-bold-condensed);
}

a.dropdownmenu__item:hover,
button.dropdownmenu__item:hover {
  background: #F0F1F2;
  color: #000000;
}

/*
ws2021: .contentpart__topsubitem-icon
ppp:    .dropdownmenu__item__icon
*/
.dropdownmenu__item__icon {
  color: var(--color-grey-normal);
  position: absolute;
  right: var(--navbar-menu-item-padding);
  top: calc((var(--navbar-menu-item-height) - 20px) / 2);
}

/*
ws2021 - .contentpart__topsubitem-icon
*/
/*
ws2021 - .contentpart__toptextitem
ppp.   - .dropdownmenu__item
*/
.ut-login__fullname {
  font-weight: 700;
}

.ut-login__loginname {
  font-size: 12px;
  margin-top: 10px;
}