html:has(dialog[open]) body {
  overflow: hidden;
  overscroll-behavior: contain;
  height: 100%;
  height: 100dvh; /* FIXME: otherwise the dialog gets centered causing part of the top to go out of view */
}

/*
Chrome's internal <dialog> styling:

dialog:-internal-modal {
    position: fixed;
    top: 0px;
    bottom: 0px;
    max-width: calc((100% - 6px) - 2em);
    max-height: calc((100% - 6px) - 2em);
    user-select: text;
    visibility: visible;
    overflow: auto;
}
*/
dialog.peoplepersonpage-filterdialog {
  /* for browsers without native <dialog> support */
  position: fixed;
  z-index: 100; /* if we aren't a dialog we aren't on the top-layer so use z-index to get on top */
  top: 0;
  bottom: 0;
  height: 100%;
  height: 100dvh; /* FIXME: otherwise the dialog gets centered causing part of the top to go out of view */
  width: 100%;
  width: 100dvw;
  margin-left: 0;
  margin-right: auto;
  padding: 0; /* disable default <dialog> padding */
  max-width: 500px;
  max-height: none; /* Chrome applies a max height to <dialog> */
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border: none;
}

dialog#slidemenu-container {
  left: 0;
  right: auto;
}

/*
@media (min-width: 550px)
{
  #slidemenu-container
  {
    width: min( calc(100% - 100px), 400px);
  }
}
*/
dialog.peoplepersonpage-filterdialog {
  left: auto;
  right: 0;
}

dialog.peoplepersonpage-filterdialog {
  display: flex;
  flex-direction: column;
  --dialog-filters-padding-h: 25px;
  --dialog-filters-padding-v: 25px;
  /*
  TRAIT: Have the teaser/apply button stick to the bottom

  .peoplepersonpage-filters__filters
  {
    flex: 1 0 auto; // grow to push the "Apply" or "X results" button to the bottom
  }
  */
}
dialog.peoplepersonpage-filterdialog .dialog__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
dialog.peoplepersonpage-filterdialog .peoplepersonpage-filters__filters {
  position: static; /* override the sticky the filter may have */
  display: block;
  padding-left: var(--dialog-filters-padding-h);
  padding-right: var(--dialog-filters-padding-h);
  margin-top: 0;
}
dialog.peoplepersonpage-filterdialog .peoplepersonpage-filters__filters__heading {
  display: none;
}
dialog.peoplepersonpage-filterdialog .filtersdialog__applybutton {
  margin-top: 40px;
  position: sticky;
  bottom: 10px;
  margin-left: var(--dialog-filters-padding-h);
  margin-right: var(--dialog-filters-padding-h);
  width: 100%;
  max-width: 200px;
  color: #FFFFFF;
  font: 700 18px/20px var(--rtd-heading-fontfamily);
  text-align: center;
  align-self: center;
  justify-content: center;
  text-transform: uppercase;
  height: 40px;
  background: var(--theme-color);
}

.dialog__header {
  position: sticky;
  top: 0;
  pointer-events: none;
}

.dialog__header .dialog__close {
  pointer-events: auto;
}

/*
Dialog header which is sticky and has a logo

.dialog__header
{
  position: sticky;
  top: 0;
  background: #FFFFFF;

  padding-top:    var(--site-menubar-padding-top);
  padding-bottom: var(--site-menubar-padding-bottom);

  border-bottom:  1px solid #ECE4EC;
}
*/
.dialog__header__content {
  display: flex;
}

.dialog__closebutton {
  margin-left: auto;
}

.dialog__closebutton {
  border: 0;
  background: none;
  display: flex;
  padding: 15px;
  color: #757575;
  cursor: pointer;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}

.dialog__closebutton:hover {
  background-color: #F0F1F2;
}

.dialog__closebutton::before {
  font-family: "UT-iconfont" !important;
  content: "\e92e";
  font-size: var(--dialog-closebutton-size);
  color: var(--dialog-closebutton-color);
}