.widget-links ul
{
  list-style: none;
  padding: 0;
  margin: 0;

  color: var(--rtd-link-color);
  font:  var(--rtd-text-compact-font);
}

.widget-links li
{
  display: flex;
  align-items: baseline;
}

/* We need the icon to be outside the <a> so we can
   ensure the icon won't get an underline on hover. */
.widget-links li::before
{
  display: block !important;

  flex: none;
  /*
  content: var(--fa-chevron-circle-right);
  font: var(--fontawesome-light);
  */
  content: var(--uticon-arrow-right-16px);
  font: var(--uticon-font);
  font-size: 16px;

  color: #AAACAE;

  width: 30px; /* keep the different icons (with different widths) aligned */

  margin-right: -30px; /* overlap into the <a> area */
}

.widget-links a
{
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 30px; /* create space for the icon to be */
  color: inherit;
  text-decoration: none;
}

.widget-links a:hover
{
  text-decoration: underline;
}

.widget-links li + li
{
  margin-top: 3px;
}

.widget-links li.isexternal::before
{
  /*
  content: var(--fa-arrow-right);
  transform: rotate(-45deg);
  font-size: 17px;

  position: relative;
  left: -2px;
  top: -4px;
  */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

/*  content: var(--fa-chevron-circle-right);*/
  content: var(--uticon-arrow-right-round-16px);
  content: var(--uticon-arrow-right-round-16px) / "";
}

.widget-links li.isreadmore
{
  text-decoration: underline;
}

.widget-links li.isreadmore::before
{
  display: none !important;
}
