/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
/*
.pageheader       - All page headers must use this class
.pageheader--base - All headers deriving from the base/default pageheader
.pageheader--search - The header includes a search bar
.pageheader--withinset - The header includes an inset image
.pageheader--size-normal
.pageheader--size-large
.pageheader--inset-square
.pageheader--inset-round

.pageheader__background
.pageheader__background--image
  .pageheader__image
.pageheader__background--universe
  .universe - The universe animation code will generate it's elements within this container

.pageheader__searchpanel
  .pagesearch__searchbar__tagline
  .pageheader__searchbar__wrapper
    .spc-searchbar__input
    .spc-searchbar__searchbutton
    .wh-autosuggest ???


.pageheader__insetimage

.pageheader__text
  .pageheader__title
*/
.pageheader {
  --menubar-pulldown-bgcolor: #FF0000;
  --pageheader-search-with-tagline-indent: 50px;
  --menubar-pulldown-pointer-width: 25px;
  --menubar-pulldown-pointer-height: 30px;
}
@media (max-width: 500px) {
  .pageheader {
    --pageheader-search-input-height: 60px;
    --pageheader-search-input-font: 700 18px var(--rtd-heading-fontfamily);
    --pageheader-search-input-radius: 10px;
    --pageheader-tagline-font: 300 26px/35px var(--rtd-heading-fontfamily);
    --pageheader-tagline-padding: 25px 25px 21px 25px;
    --menubar-pulldown-pointer-width: 30px;
    --menubar-pulldown-pointer-height: 30px;
  }
}
@media (min-width: 501px) and (max-width: 750px) {
  .pageheader {
    --pageheader-search-input-height: 80px;
    --pageheader-search-input-font: 300 26px var(--rtd-heading-fontfamily);
    --pageheader-search-input-radius: 10px;
    --pageheader-tagline-font: 700 26px/35px var(--rtd-heading-fontfamily);
    --pageheader-tagline-padding: 28px 30px 24px 30px;
  }
}
@media (min-width: 751px) {
  .pageheader {
    --pageheader-search-input-height: 100px;
    --pageheader-search-input-font: 300 26px var(--rtd-heading-fontfamily);
    --pageheader-search-input-radius: 10px;
    --pageheader-tagline-font: 300 40px var(--rtd-heading-fontfamily);
    --pageheader-tagline-padding: 25px 23px;
  }
}
@media (min-width: 750px) {
  .pageheader {
    --pageheader-search-with-tagline-indent: 51px;
    --menubar-pulldown-pointer-width: 30px;
    --menubar-pulldown-pointer-height: 30px;
  }
}
@media (min-width: 1080px) {
  .pageheader {
    --pageheader-search-with-tagline-indent: 60px;
    --menubar-pulldown-pointer-width: 35px;
    --menubar-pulldown-pointer-height: 35px;
  }
}
@media (min-width: 1280px) {
  .pageheader {
    --pageheader-search-with-tagline-indent: 95px;
    --menubar-pulldown-pointer-width: 40px;
    --menubar-pulldown-pointer-height: 40px;
  }
}

.pageheader--base {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: [messages] min-content [header-image] minmax(min-content, 1fr) [search] min-content [below-image] var(--pageheader-extrude-height) [title-overflow] min-content; /* allow the title to overflow from the "extrude" area into this part - so the header grows with the title */
  --pageheader-col-background: var(--pagegrid-col-verywide-outsidegutter-bg);
  --pageheader-extrude-height: 0px;
}

.pageheader--search:not(.pageheader--size-large) {
  --pageheader-extrude-height: calc(var(--pageheader-search-input-height) / 2);
}

.pageheader--withinset {
  --pageheader-extrude-height: 90px;
}

.pageheader--base.pageheader--size-normal .pageheader__searchpanel {
  grid-row: search/span 2;
}

.pageheader--size-large .pageheader__searchpanel {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pageheader__background {
  grid-row: header-image/span 2;
  grid-column: var(--pageheader-col-background);
  border-radius: var(--pageheader-border-radius);
  overflow: hidden;
  min-height: var(--pageheader-height);
}

.pageheader__background--image {
  position: relative;
}

.pageheader__background--universe {
  position: relative; /* otherwise absolute positioned content would escape the overflow: hidden; */
  overflow: hidden;
}

.pageheader__background--universe {
  background-color: #F0F1F2;
}

.pageheader__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pageheader__buildinglabel {
  display: grid;
  grid-template-columns: 15px max-content;
  grid-template-rows: 20px max-content;
  left: calc(var(--label-refx) * 100%);
  position: absolute;
  top: calc(var(--label-refy) * 100%);
  transform: scale(var(--label-flipx), var(--label-flipy));
  transform-origin: 0% 0%;
}

.pageheader__buildinglabel-line {
  grid-column: 1;
  grid-row: 1;
  position: relative;
}
.pageheader__buildinglabel-line::after {
  border-top: 1px solid var(--color-dogwood-rose);
  content: "";
  position: absolute;
  transform: rotate(53deg);
  transform-origin: 0% 0%;
  width: 25px;
}

/*
  In browsers supporting sqrt/pow and atan we can use arbitrary width and height values for the line box using CSS.
  sqrt, pow: Chrome/Edge 120+, Firefox 118+, Safari 15.4+, Samsung Internet 25.0+
  atan: Chrome/Edge 111+, Firefox 108+, Safari 15.4+, Sansung Internet 22.0+
*/
@supports (width: calc(sqrt(25) * 1px)) {
  .pageheader__buildinglabel {
    --line-box-width: 15;
    --line-box-height: 20;
    grid-template-columns: calc(var(--line-box-width) * 1px) max-content;
    grid-template-rows: calc(var(--line-box-height) * 1px) max-content;
  }
  .pageheader__buildinglabel-line::after {
    transform: rotate(atan(var(--line-box-height) / var(--line-box-width)));
    width: calc(sqrt(pow(var(--line-box-width), 2) + pow(var(--line-box-height), 2)) * 1px);
  }
}
.pageheader__buildinglabel-label {
  background: var(--color-dogwood-rose);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  grid-column: 2;
  grid-row: 2;
  padding: 3px 10px 4px;
  transform: scale(var(--label-flipx), var(--label-flipy));
  transform-origin: 50% 50%;
}

.pageheader__insetimage {
  width: 180px;
  height: 180px;
  padding: 10px;
  background-color: #fff;
  margin-left: -10px;
  border-radius: 10px;
  z-index: 1;
  grid-column: 3;
  grid-row: below-image;
  align-self: end;
}

.pageheader--inset-round .pageheader__insetimage {
  border-radius: 50%;
}

.pageheader__text {
  grid-row: below-image/span 2;
  grid-column: var(--pagegrid-col-verywide);
  z-index: 1; /* over a positioned .pageheader__background */
  margin-top: 50px; /* 50px distance - line-height whitespace */
}

.pageheader--search .pageheader__text {
  grid-row: header-image;
  align-self: center;
  grid-column: var(--pagegrid-col-verywide);
  width: 100%;
  max-width: calc(727px + var(--pageheader-search-with-tagline-indent));
}

.pageheader--withinset .pageheader__text {
  grid-row: below-image/span 2;
  margin-left: 220px;
}

.pageheader__title {
  font: var(--rtd-h1-font-header);
  text-wrap: balance;
  letter-spacing: 0.5px;
}

.pageheader__title__suffix {
  font-weight: 300;
}

.pageheader__subtitle {
  margin-top: 7px;
}

@media (max-width: 550px) {
  .pageheader--withinset {
    --pageheader-extrude-height: 30px;
  }
  .pageheader__insetimage.pageheader__insetimage {
    grid-column: var(--pagegrid-col-verywide);
    justify-self: center;
    width: 120px;
    height: 120px;
    padding: 5px;
  }
  .pageheader__text.pageheader__text {
    grid-row: title-overflow;
    grid-column: var(--pagegrid-col-verywide);
    margin-left: 0;
  }
}
.pageheader__searchpanel {
  grid-row: header-image;
  grid-column: 3/26;
  width: 100%;
  max-width: calc(727px + var(--pageheader-search-with-tagline-indent));
  align-self: center;
  justify-self: center;
  position: relative; /* ensure we are drawn OVER the universe animation */
  --formcontrols-radius: var(--pageheader-search-input-radius);
  --formcontrols-height: var(--pageheader-search-input-height);
  --textcontrols-font: var(--pageheader-search-input-font);
}

.pagesearch__searchbar__tagline {
  display: inline-block;
  background: var(--theme-color);
  color: #FFFFFF;
  font: var(--pageheader-tagline-font);
  text-transform: uppercase;
  leading-trim: both; /* SF18+ ? - https://caniuse.com/?search=leading-trim) */
  text-edge: cap alphabetic;
  padding: var(--pageheader-tagline-padding);
  position: relative;
}

.pagesearch__searchbar__tagline::after {
  position: absolute;
  left: calc(var(--pageheader-search-with-tagline-indent) - var(--menubar-pulldown-pointer-width));
  bottom: calc(-1 * var(--menubar-pulldown-pointer-height));
  content: " ";
  border-width: var(--menubar-pulldown-pointer-width) var(--menubar-pulldown-pointer-width) 0 var(--menubar-pulldown-pointer-width);
  border-style: solid;
  border-color: transparent transparent transparent transparent;
  border-top: var(--menubar-pulldown-pointer-height) solid var(--theme-color);
}

.pagesearch__searchbar__tagline + .pageheader__searchbar__wrapper {
  margin-left: var(--pageheader-search-with-tagline-indent);
  position: relative;
}

.pageheader__searchbar__wrapper .spc-searchbar__input {
  width: 100%;
  /* Replace some of the padding with text-indent to prevent the input padding from cutting off the left side of the italic text */
  --textcontrols_padleft: min(90px, 10% + 30px);
  text-indent: 10px;
  box-shadow: 0 30px 30px -30px rgba(0, 0, 0, 0.25);
}

/* NOTE: Chrome & SF allow ::placeholder */
.pageheader__searchbar__wrapper .spc-searchbar__input {
  font: 15px var(--rtd-text-fontfamily);
  font-style: italic;
  font-style: oblique;
  transform: translateY(-3px);
}

.pageheader__searchbar__wrapper .spc-searchbar__input::placeholder {
  color: #5A5E62;
  opacity: 1;
}

.pageheader__searchbar__wrapper .spc-searchbar {
  position: relative;
}

.pageheader__searchbar__wrapper .spc-searchbar__searchbutton {
  color: var(--color-black);
  padding: 10px;
  border: 0;
  background: none;
  position: absolute;
  left: min(5%, 49px);
  top: 50%;
  transform: translateY(-50%);
}

.pageheader__searchbar__wrapper .spc-searchbar__searchbutton::before {
  font-family: "UT-iconfont" !important;
  content: "\e96b";
  font-size: 24px;
}

/* When the filterpage 2-column layout is active,
   we need to align the header and it's content with the
   filter and content columns. */
@media (min-width: 768px) {
  .peoplepersonpage .pageheader {
    grid-column: 1/-1;
    grid-template-columns: subgrid;
    --pageheader-col-background: 2 / 7;
  }
  .peoplepersonpage .pageheader .pageheader__searchpanel {
    grid-column: var(--pagegrid-col-withsidebar-content);
    max-width: none;
  }
  .peoplepersonpage .pageheader .pageheader__text {
    grid-column: var(--pagegrid-col-withsidebar-content);
    margin-left: 0;
  }
  .peoplepersonpage .pageheader .pageheader__background {
    min-height: var(--pageheader-height);
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .peoplepersonpage .pageheader .pageheader__searchpanel {
    grid-column: 3/-3;
  }
}
.pageheader__messages {
  grid-row: messages;
  grid-column: 2/-2;
}
@media (max-width: 550px) {
  .pageheader__messages {
    grid-column: 1/-1;
  }
}

.pageheader__message {
  align-items: center;
  background: var(--color-dogwood-rose);
  border-radius: 10px;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 300;
  gap: 20px;
  min-height: 60px;
  line-height: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
}
@media (max-width: 550px) {
  .pageheader__message {
    align-items: flex-start;
    border-radius: 0;
    margin-bottom: 10px;
  }
}
.pageheader__message a {
  color: inherit;
  white-space: nowrap;
}

.pageheader__message-icon {
  font-size: 20px;
}
@media (max-width: 550px) {
  .pageheader__message-icon {
    margin-top: 10px;
  }
}

.pageheader__message-content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
}
@media (max-width: 550px) {
  .pageheader__message-content {
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
  }
}

.pageheader__message-close {
  background: none;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  font-size: 16px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
}