/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
.header-menubar {
  min-height: var(--site-menubar-min-height);
}

.header-top {
  min-height: var(--site-topbar-min-height);
}

.header-top__content {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.header-top__utlogo {
  display: block;
  height: 20px;
}

.header-top-background {
  background: var(--color-black);
  grid-column: var(--pagegrid-col-fullwidth);
}

@media (min-width: 950px) {
  html {
    /* the height when the page has scrolled */
    --siteheader-height-approximation: 133px;
    --siteheader-deeplinks-spacing: 30px;
  }
}
@media (max-width: 949px) {
  html {
    --siteheader-height-approximation: 39px;
    --siteheader-deeplinks-spacing: 20px;
  }
}
/***********************************************************************
**
**  Header layout
*/
.header-top {
  z-index: 1;
  /* workaround for Safari 15.5 renderbug in which the menubar overlaps the button (stickout out of the topbar) even though the topbar has a higher z-index */
  -webkit-transform: translate3d(0, 0, 0);
}

.header-top,
.header-menubar {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top__content,
.header-menubar__content {
  grid-column: var(--site-menubar-grid-column);
  display: flex;
  align-items: center;
}

.header-menubar-background,
.header-menubar {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
}

.header-menubar-background {
  background-color: var(--site-menubar-background);
  grid-column: var(--site-menubar-background-grid-column);
}

.header-menubar {
  padding-top: var(--site-menubar-padding-top);
  padding-bottom: var(--site-menubar-padding-bottom);
}

.header-menubar__mainorganization {
  padding-top: 6px;
  padding-bottom: 10px;
}

.header-menubar__content {
  align-self: center;
  /*  padding-left: 9px; - utwente_ppp specific */
}

.header-menubar__utlogo {
  display: block; /* prevent whitespace because of line */
  height: 42px;
}

.header-menubar__mainorganization {
  flex: 0 1 auto;
}

.header-menubar__spacer {
  margin-left: 16px;
  border-left: 1px solid #DCDDDE;
  margin-right: 16px;
  align-self: stretch;
}

.header-menubar__backnav {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: none;
}

.header-menubar__backnav__home {
  font: 700 23px/21.6px var(--rtd-heading-fontfamily);
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
}

.header-menubar__backnav__backlink {
  margin-bottom: -7px;
}

.header-menubar__backnav--withbacklink {
  display: flex;
  flex-direction: column;
}
.header-menubar__backnav--withbacklink .header-menubar__backnav__home br {
  display: none;
}

.header-menubar__backnav__backlink {
  margin-top: auto;
  display: flex;
  align-items: center;
  color: #53575B;
  text-decoration: none;
  white-space: nowrap;
}

/* UT-icon-UT_icon_43_arrow-left-16px */
.header-menubar__backnav__backlink::before {
  content: "\e92a";
  font-family: "UT-iconfont" !important;
  font-size: 11px;
  margin-right: 3px;
  color: var(--theme-color-cta);
  /* Better Font Rendering - making the icon looks a little thinner/crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-menubar__backnav__backlink:hover span {
  text-decoration: underline;
}

/*
.header-menubar__identity
{
  display: grid;
}

  .header-menubar__identity a
, .header-menubar__identity img
{
  grid-column: 1;
  grid-row: 1;
}

.header-menubar__homelink
{
  z-index: 0; // FIX for Safari (tested in 16.5) <a> not clickable (seems to be drawn OVER the image which is correct, but hitdectection sees it as below??)
}
*/
@media (min-width: 950px) {
  .header-menubar {
    color: #FFFFFF;
  }
  .header-menubar {
    top: -7px;
  }
  .header-menubar-background {
    top: -14px;
  }
}
@media (max-width: 949px) {
  html {
    --siteheader-height-approximation: 39px;
  }
  .header-top {
    display: none;
  }
  .header-menubar {
    top: -4px;
  }
  .header-menubar-background {
    top: -8px;
  }
}