/*
  .widget-cta--focusleft:not(.widget-cta--compact)
, .widget-cta--focusright:not(.widget-cta--compact)
*/
  .widget-cta--large.widget-cta--focusleft
, .widget-cta--large.widget-cta--focusright
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;
  z-index: var(--zindex-overlap-deeplinks);
/*  --widget-cta-background-col: var(--pagegrid-col-verywide-outsidegutter-bg);*/
}

.colortheme--no-background
{
  /*
  --widget-cta-sidebyside-padding-top: 0;
  --widget-cta-sidebyside-padding-bottom: 0;
  */
}

.widget-cta--compact
{
  --widget-cta-sidebyside-padding-top: 17px;
  --widget-cta-sidebyside-padding-bottom: 40px;
}

.widget-cta .widget__title__suffix
{
  font-weight: 500;
}



@media (max-width: 750px)
{
  .widget-cta
  {
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .widget-cta + .widget-cta
  {
    margin-top: 50px;
    border-top: 1px solid #DCDDDE;
    padding-top: 50px;
  }

  .widget-cta__content
  {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .widget-cta__imagewrapper
  {
    grid-column: var(--pagegrid-col-verywide);
  }

  .widget-cta__imagewrapper img
  {
    max-width: 100%;
  }

  .widget-cta__text
  {
    grid-column: var(--pagegrid-col-verywide);
    padding-top:    var(--widget-cta-stacked-inside-padding-top);
    padding-bottom: var(--widget-cta-stacked-inside-padding-bottom);
  }
}


/*
// Desktop - image and text side by side
// @media (min-width: $widget-cta__minwidth-sidebyside)
*/
@media (min-width: 751px)
{
  .widget-cta
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  /* Background container */
    .widget-cta--focusleft.widget-cta--withbackground::before
  , .widget-cta--focusright.widget-cta--withbackground::before
  {
    content: "";
    display: block;
    grid-column: var(--widget-cta-background-col);
    grid-row: 1 / 2;

    background-color: var(--currenttheme-background);
    border-radius: var(--widget-cta-sidebyside-radius);
  }

  .widget-cta--compact.widget-cta--withbackground::before
  {
    content: "";
    display: block;
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1 / 2;

    background-color: var(--currenttheme-background);
    border-radius: var(--widget-cta-sidebyside-radius);
  }




  .widget-cta--withbackground .widget-cta__content
  {
    grid-row: 1;
    margin-top:    var(--widget-cta-sidebyside-padding-top);
    margin-bottom: var(--widget-cta-sidebyside-padding-bottom);
  }
}



/*
// Tablet / desktop
// @media (min-width: $widget-cta__minwidth-sidebyside)
*/
@media (min-width: 751px)
{
    .widget-cta--focusleft  .widget-cta__content
  , .widget-cta--focusright .widget-cta__content
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }



  /* use the full 12-column contentarea and have our padding inside that area too */
  .widget-cta--compact
  {
    --rtd-text-font: var(--rtd-text-subtle-font);
  }
  .widget-cta--compact .widget-cta__content
  {
    padding: 0 20px;
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
  }
  .widget-cta--compact .widget-cta__image
  {
    width: 313px;
  }



  .widget-cta--compact.widget-cta--focusleft .widget-cta__image
  {
    margin-right: 40px;
  }

  .widget-cta--compact.widget-cta--focusright .widget-cta__imagewrapper
  {
    order: 2;
  }
  .widget-cta--compact.widget-cta--focusright .widget-cta__image
  {
    margin-left: 40px;
  }



  /* Large CTA (outside contentarea) + focus left */
  /* .widget-cta--focusleft:not(.widget-cta--compact) .widget-cta__text */
  .widget-cta--focusleft.widget-cta--large .widget-cta__text
  {
    grid-row: 1;
    grid-column: var(--widget-cta-focusleft-text-col);
    margin-left: var(--widget-cta-extra-spacing);
  }
  /* .widget-cta--focusleft:not(.widget-cta--compact) .widget-cta__imagewrapper */
  .widget-cta--focusleft.widget-cta--large .widget-cta__imagewrapper
  {
    grid-row: 1;
    grid-column: var(--widget-cta-focusleft-image-col);
    margin-left: var(--widget-cta-keepfromedge);
  }



  /* .widget-cta--focusright:not(.widget-cta--compact) .widget-cta__text */
  .widget-cta--focusright.widget-cta--large .widget-cta__text
  {
    grid-row: 1;
    grid-column: var(--widget-cta-focusright-text-col);
    margin-left: var(--widget-cta-keepfromedge);
    margin-right: var(--widget-cta-extra-spacing);
  }
  /* .widget-cta--focusright:not(.widget-cta--compact) .widget-cta__imagewrapper */
  .widget-cta--focusright.widget-cta--large .widget-cta__imagewrapper
  {
    grid-row: 1;
    grid-column: var(--widget-cta-focusright-image-col);
    margin-right: var(--widget-cta-keepfromedge);
  }


  .widget-cta__image
  {
    width: 100%;
    height: 100%;
  }



  .widget-cta__text
  {
    padding-top:    var(--widget-cta-sidebyside-inside-padding-top);
    padding-bottom: var(--widget-cta-sidebyside-inside-padding-bottom);
  }

    /* with a grey background we place the whitespace around both
       the image and text.

    .widget-cta.colortheme--grey
    {
      padding-top:    var(--widget-generic-bigpanel-padding-top);
      padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
    }
    .widget-cta.colortheme--grey .widget-cta__text
    {
      padding-top: 0;
      padding-bottom: 0;
    }
    */

}



/*
When there is a background color we cannot use a color for the anchor.
So we need to use underline to indicate it's an link

// - target within the .rtdcontent, we don't want to target the .wh-form__button is also an anchor!
// - we only allow p.normal (and .widget-button), so we only need to target anchors within p.normal
// - we can also have a .widget-button within the rtdcontent container, which is also an <a>, so prevent targetting that one
*/
.widget-cta--withbackground .rtdcontent > p.normal > a
{
  text-decoration: underline;
}

.widget-cta--withbackground .widget-cta__content
{
  /* Don't directly apply this to our main container,
     because the themecolors are applied on it and if the themecolors
     use the default --rtd-* colors this causes issues.
  */
  --rtd-link-color:    var(--currenttheme-text-color);
  --rtd-heading-color: var(--currenttheme-heading-color);
  --rtd-text-color:    var(--currenttheme-text-color);
}




.widget-cta__image
{
  object-fit: cover;
  border-radius: var(--widget-cta-image-radius);
}

/* If the whole image is important we don't apply radius because we might clip */
.widget-cta--makeimagefit .widget-cta__image
{
/*  border-radius: 0;*/
  height: auto !important;
}

.widget-cta__subtitle + .widget-cta__title
{
  margin-top: 4px;
}

  .widget-cta__text > * + .widget-cta__description
, .widget-cta__text > * + .widget-cta__button
{
  margin-top: 13px;
}
