/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
/*

Implementation notes:

- Don't use position: absolute; on .wh-anchor,
  this would mess up the position when used in a grid. (it'll end up at the top of the grid)

- Use transform (instead of a negative margin-top) because this won't effect the flow
  (or otherwise have a padding-top equal to the margin-top and make this this now large
  area the wh-anchor occupies isn't interactable/clickable)

- Once iOS 14.8 is commonplace it might be better to switch to using
  scroll-padding-top on the <body> instead of using a transform on the wh-anchor.
  (Safari and iOS prior to this didn't take scroll-padding-top into acount
  when using scrollIntoView() on an element.

*/
.wh-anchor,
.wh-rtd__anchor,
.deeplinktarget {
  position: relative;
  pointer-events: none;
}

@media (max-width: 949px) {
  .header-menubar {
    display: none;
  }
  .wh-anchor,
  .wh-rtd__anchor,
  .deeplinktarget {
    position: relative;
    pointer-events: none;
  }
}
.header-previewbar {
  margin-right: 30px;
  color: #FFFFFF;
  font: var(--rtd-text-subtle-font);
  background: var(--color-purple-background);
  border-bottom: 2px solid var(--color-theme);
  border-radius: var(--contentpanels-radius);
  padding: 5px 15px;
}