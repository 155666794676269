.widget-tagslist
{

}

.widget-tagslist
{
  text-wrap: pretty;
  text-wrap: balanced;
}

.tagslist__prefix
{
  font: 700 17px var(--rtd-heading-fontfamily);
  margin-right: 15px;
/*  vertical-align: baseline;*/
}

.widget-tagslist .spc-tag
{
  margin-bottom: var(--component-taglist-gap-v);
/*  vertical-align: top;*/
}
