.spc-tagslist
{
  display:    flex;
  flex-wrap:  wrap;
  column-gap: var(--component-taglist-gap-h);
  row-gap:    var(--component-taglist-gap-v);
}

.spc-tag
{
  display: inline-flex;
  align-items: center;

  color: var(--component-tag-text-color);
  background: var(--component-tag-background);

  min-height: var(--component-tag-height);

  font: var(--component-tag-font);

  border: 1px solid var(--component-tag-border-color);
  border-radius: var(--component-tag-radius);

  /* the top has an extra 4px to compensate for Officina's weird font alignment */
  padding: var(--component-tag-padding);

  text-decoration: none;
}

  a.spc-tag
, .spc-tag[data-spclink]
, .spc-tag[for] /* we assume this means it's used as <label> for a checkbox */
{
  cursor: pointer;
}

.comp-togglebuttonbar input:focus-visible + label.spc-tag
{
  box-shadow:     var(--formcontrols-focus-boxshadow);
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);
}

  a.spc-tag:hover
, .spc-tag[data-spclink]:hover
, .spc-tag[for]:hover /* we assume this means it's used as <label> for a checkbox */
, .comp-togglebuttonbar input:checked + label.spc-tag
{
  background: var(--component-tag-hover-background);
  color: var(--component-tag-hover-text-color);
  text-decoration: underline;
}
