.publications
{

}

.publications__year
{
}

.publications__year__title
{
  font: inherit; /* cancel the <h3> styling */
  margin-bottom: 45px;
}

.publicationslist
{

}

.publicationitem
{

}

.publicationitem + .publicationitem
{
  margin-top: 40px;
}

.publicationitem__title
{
  font-weight: bold;
}

.publicationitem__meta
{
  color: #5A5E62;
  text-wrap: pretty;
}

.publicationitem__publishedin
{
  font-style: italic;
}

.publicationitem__authors
{
}

.publicationitem__url
{
  display: flex;
  color: #5A5E62;
  font-size: 13px;
  border-bottom: 1px solid #DCDDDE;

  align-items: center;
}

.publicationitem__url__icon
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
/*  background: #EEEEEE;*/
  background-color: rgba(240,241,242,0.5);
  border: 1px solid #DCDDDE;

  margin-right: 10px;
}
