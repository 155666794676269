/*

To prevent underline on the button icon:

- Prevent the icon in the button get's underline by:
  - Option 1) wrap the text and target the underline at that wrapper
  - Option 2) giving the icon position: absolute; so it's out of flow and won't receive the underline
              But now we have to compensate the padding-left of the button and give the icon a negative margin-left
              OR give the icon position: absolute; + left: ..
  - Option 3) Paint the underline of the symbol over the underline of the button in the background color.


Origin:
ZVI (2023) < ACOI (2022) < 4TU


Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward


In the future we can (probably) use CSS container styles:

    @container button style(--spc-appearance: button-solid)
    {
      border:     var(--button-border);
      background: var(--button-background);
      color:      var(--button-text-color);

      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    }
*/

  .spc-button
, .wh-form__button
, .spc-searchbar__searchbutton
, .multiselect__panel__ok
{
  /* clean default form <button> stylings */
  border: 0 none;

  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;

  outline-offset: var(--formcontrols-focus-outline-offset);


  display: inline-flex;
  align-items: center;

  /* Use min-height to allow the button to stretch in height when it goes multiline */
  min-height: var(--button-height);
  padding: var(--button-padding-v) var(--button-padding-side-noicon);
  border-radius: var(--button-borderradius);

  font: var(--button-font);

  /* DON'T use "white-space: nowrap;", it might cause overflow, especially on mobile screens. */

  cursor: pointer;

  /* FIXME: implement ? */
  --focus-shadow-color: var(--button-focus-shadow-color);

  transition: color 0.2s
            , background-color 0.2s;
}


/* FIXME: in the future use style container (assuming it got picked up) */
  .spc-button--solid
, .wh-form__button--solid
, .widget-cta .wh-form__button--cta /* FIXME: rather want some site specific theming way to do this ... mixin?? */
, .widget-ctacarrousel .wh-form__button--cta
, .wh-form__buttongroup .wh-form__button--next
, .wh-form__buttongroup .wh-form__button--submit
, .wh-form__uploadfieldselect

, .spc-button--outline
, .wh-form__button--outline
, .wh-form__buttongroup .wh-form__button--previous

, .filteredoverview__teaser__button
, .multiselect__panel__ok
{
  border:     var(--button-border);
  background: var(--button-background);
  color:      var(--button-text-color);

  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
/*
  &:hover
  {
    border:     var(--button-hover-border);
    background: var(--button-hover-background);
    color:      var(--button-hover-text-color);

    text-decoration: underline;
  }

    &::before
  , &::after
  {
    / * Used to paint away the underline from our parent * /
    text-underline-offset: 2px;
    text-decoration-thickness: 4px;
    text-decoration-color: var(--button-underline-color);
  }
*/
}

/*
@media (min-width: 768px)
{
  .spc-searchbar__searchbutton
  {
    border:     var(--button-border);
    background: var(--button-background);
    color:      var(--button-text-color);

    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
}
*/

  .spc-button--solid:hover
, .wh-form__button--solid:hover
, .widget-cta .wh-form__button--cta:hover /* FIXME: rather want some site specific theming way to do this ... mixin?? */
, .widget-ctacarrousel .wh-form__button--cta:hover
, .wh-form__buttongroup .wh-form__button--next:hover
, .wh-form__buttongroup .wh-form__button--submit:hover
, .wh-form__uploadfieldselect:hover

, .spc-button--outline:hover
, .wh-form__button--outline:hover
, .wh-form__buttongroup .wh-form__button--previous:hover

, .filteredoverview__teaser__button:hover
{
  border:     var(--button-hover-border);
  background: var(--button-hover-background);
  color:      var(--button-hover-text-color);

  text-decoration: underline;

  --button-icon-background: var(--button-hover-icon-background);
  --button-icon-color:      var(--button-hover-icon-color);
}

.widget-button .wh-form__button:hover
{
  text-decoration: none;
}
.widget-button .wh-form__button:hover .spc-button__text
{
  text-decoration: underline;
}




  .spc-button:focus
, .wh-form__button:focus
, .filteredoverview__teaser__button:focus
{
  outline:        var(--formcontrols-focus-outline);
  outline-offset: var(--formcontrols-focus-outline-offset);

/*

  // box-shadow: var(--color-purple-focus-boxshadow);
  box-shadow: var(--formcontrols-focus-boxshadow);
  outline: 3px solid #540A5130;
  outline-offset: 2px;
*/

  /* https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */
/*
  //box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: componentfocus 2s infinite;
*/
}



/*.wh-form__button--disabled*/
.wh-form__button[disabled]
{
  --button-color: #999999; /* FIXME - untested */
  cursor: auto;
}





  .wh-form__button--backward /* icon for button */
, .wh-form__button--previous /* Webhare forms back button */
{
  padding-left: var(--button-padding-side-withicon);
}


/* Back/previous arrow */
  .wh-form__button--backward::before
, .wh-form .wh-form__button--previous::before
, .spc-textbutton--backward::before
{
  font: var(--fontawesome-solid);
  /* content: var(--fa-arrow-left); */
  content: var(--fa-chevron-left);
  font-size: 13px;

  margin-right: 15px;
}





.spc-textbutton .wh-form__button--backward::before
{
  margin-right: 15px;
}





.spc-textbutton--forward::before
{
  font: var(--fontawesome-solid);
  content: var(--fa-arrow-right);

  font-size: 13px;
  vertical-align: middle;

  margin-right: 15px;
}


.spc-textbutton .spc-button__icon::before
{
  margin-right: 8px;

  display: inline-block;
  font: var(--fontawesome-regular);
  font-size: 13px;

  content: var(--fa-chevron-right);
}





/* In case of buttons with a fixed width */
  .wh-form__button--next
, .wh-form__button--submit
{
  justify-content: space-between;
}



form.wh-form--submitting .wh-form__button--submit
{
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}



/*
// Textual button has too many differences to the outline and solid,
// so we keep it as a seperate class name.
*/
.spc-textbutton
{
  display: block;
  color: var(--textbutton-color);
  font: var(--button-textbutton-font);
  text-decoration: none;
}

.spc-textbutton:hover
{
  color: var(--textbutton-hover-color);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.widget-news__morelink::after
{
  margin-left: 12px;

  display: inline-block;
  font: var(--fontawesome-regular);
  font-size: 15px;

  content: var(--fa-chevron-right);
}
