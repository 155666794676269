/*.sidebar__socials*/

.spc-socialsbar
{
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
}

.spc-socialsbar a
{
  text-decoration: none;
  color: var(--sharebar-icon-color);
  font-size: var(--sharebar-icon-size);
}
