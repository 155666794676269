.searchbar
{
  display: flex;
  flex-wrap: wrap; /* this way the suggestions pulldown can wrap to the next line and inherit our width - even though the pulldown is absolutely positioned */

  position: relative;
  margin-left:  var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);

  /* new stacking content so we can have the input be over the autocomplete dialog without z-index messing with other page content */
  z-index: 0;
}
.searchbar__input
{
  z-index: 1;
}
.searchbar .wh-autocomplete-container
{
  z-index: 0;
}


  .peoplepersonpage-custombar .searchbar
{
  --formcontrols-height: 46px;
  --formcontrols-radius: 23px;
}

  .peoplepersonpage-filters__filters
, .deeplinks .searchbar
{
  --formcontrols-height: 40px;
}

.deeplinks > * + .pagegutter__searchbar
{
  margin-top: var(--filterpage-filtergroup-spacing);
}



.filtergroup + .searchbar
{
  margin-top: 30px;
}

.searchbar + *
{
  margin-top: 20px;
}

.searchbar__input.searchbar__input
{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: -webkit-fill-available;
  width: -moz-available;
  height: var(--formcontrols-height);

  padding-left:  var(--filterpage-filtergroup-padleft);
  padding-right: 45px;

  font: 16px var(--rtd-text-fontfamily);

/*  border-radius: var(--formcontrols-radius);*/
/*  border-radius: var(--contentpanels-radius-small);*/

/*
  background-color: #FFFFFF;
  border: 1px solid var(--color-theme);
*/
}

  .peoplepersonpage-filters__filters .searchbar__input
, .deeplinks .searchbar__input
{
  background: #F0E8F5;
  border-color: transparent;
  border-radius: 21px;
}


.searchbar__input.searchbar__input::placeholder
{
  color: #767676;
  opacity: 1;
}


.searchbar__input::-webkit-search-cancel-button
{
  -webkit-appearance:none;
}

/*
.searchbar__input.searchbar__input:focus
{
  // outline: 0;
  // border: 2px solid #FFFFFF;
  // box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  // border-color: #FFFFFF;
}
*/

.searchbar__input.searchbar__input::selection
{
  color: #FFFFFF;
  background-color: var(--color-theme);
}

.searchbar__submitbutton
{
  border: 0;
  background: none;

  position: absolute;
/*  right: var(--filterpage-filtergroup-padright);*/
  right: 0;
  top: 0;
  padding: 0 var(--filterpage-filtergroup-padright) 0 10px; /* increate hitarea */
/*  margin-right: -10px;*/

  border-radius: 0 21px 21px 0;

  height: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.searchbar__submitbutton::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-search);
  font-size: 16px;
  color: var(--color-theme);
}

.searchbar__submitbutton:hover
{
  background-color: rgba(255, 255, 255, 0.2);
}

.searchbar__submitbutton:hover::before
{
  color: #000000;
}
