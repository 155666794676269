html
{
  /* 34px */
  --widget-cta-sidebyside-padding-top:           34px; /*var(--widget-generic-panel-padding-top);*/
  --widget-cta-sidebyside-padding-bottom:        34px; /*var(--widget-generic-panel-padding-bottom);*/
  --widget-cta-sidebyside-inside-padding-top:    20px;
  --widget-cta-sidebyside-inside-padding-bottom: 0;

  --widget-cta-stacked-inside-padding-top:    45px;
  --widget-cta-stacked-inside-padding-bottom: 0; /* 25px; */

  --widget-cta-sidebyside-radius: var(--contentpanels-radius-medium);
  --widget-cta-image-radius:      var(--contentpanels-radius-medium);
}

  .widget-cta--large.widget-cta--focusleft
, .widget-cta--large.widget-cta--focusright
{
/*  --widget-cta-background-col: var(--pagegrid-col-verywide-outsidegutter-bg);*/
  --widget-cta-background-col: var(--pagegrid-col-verywide);
}
