.debugpanel--customcontent
{
  position: absolute;
  top: 101px;
  right: 0;

  width: 300px;

  background-color: rgba(255,128,0, 0.6);
  backdrop-filter: blur(2px);
  font: 14px/15px Arial;
}

.debugpanel details summary
{
  padding: 5px 0;
}

.accordionitem__content
{
  padding: 8px 0 15px 0;
}

.debugpanel--customcontent ul
{
  margin-top: 0;
  margin-bottom: 0;
}

.debugpanel--customcontent h2
{
  font: var(--rtd-h3-font);
  text-transform: none;
}

.debugpanel--customcontent h3
{
  margin-top: 15px;
  margin-bottom: 0;

  font: var(--rtd-h4-font);
  text-transform: none;

  padding: 0 0 0 40px;
}

.debugpanel summary
{
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.debugpanel summary::before
{
  content: ">";
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;

  position: relative;
  top: 0px;
}

.debugpanel--customcontent li + li
{
  margin-top: 8px;
}

.debugpanel--customcontent a
{
  color: #000000;
}
