/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
@media (max-width: 1299px) {
  .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    grid-row: 1;
    grid-column: var(--pagegrid-col-default);
  }
  .deeplinks__item {
    display: none;
  }
}
@media (min-width: 1300px) {
  .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    grid-column: var(--pagegrid-gutter-left);
    margin-right: 45px;
    align-self: start;
    /* Keep the gutter content (such as backlink, page index links and share buttons)
       visible in the viewport just below the sticky siteheader/menubar. */
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--siteheader-height-approximation) + var(--siteheader-deeplinks-spacing));
  }
  .deeplinks {
    /* take out of flow so content isn't pushed down.
       (or in case of our grid our row won't be stretched)
    */
    position: absolute;
  }
}
/* Op de profiel pagina tonen we alleen de snellinks naar profiel headers
   in de gutter en dit moet altijd links van de header foto getoond.
   (tot het moment dat je in de mobiele layout komt en de links verborgen worden)
*/
@media (min-width: 800px) {
  html.page--profile .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    grid-column: var(--pagegrid-gutter-left);
    align-self: start;
    /* Keep the gutter content (such as backlink, page index links and share buttons)
       visible in the viewport just below the sticky siteheader/menubar. */
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--siteheader-height-approximation) + var(--siteheader-deeplinks-spacing));
  }
  html.page--profile .deeplinks {
    /* take out of flow so content isn't pushed down.
       (or in case of our grid our row won't be stretched)
    */
    position: absolute;
  }
}
.gutter_inpagelinks {
  pointer-events: auto;
}

a.gutter_inpagelinks__item {
  padding: 2px 0; /* increase hitarea */
  /*
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    align-items: baseline;
  */
  display: flex;
  align-items: baseline;
  font: 15px var(--rtd-text-fontfamily);
  color: var(--color-theme);
  text-decoration: none;
  outline-offset: 4px;
}

a.gutter_inpagelinks__item::before {
  display: inline-block;
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  font-size: 12px;
  color: var(--color-theme);
  text-decoration: none;
  margin-right: 16px;
}

a.gutter_inpagelinks__item:hover span {
  text-decoration: underline;
}

.gutter_inpagelinks__item + .gutter_inpagelinks__item {
  margin-top: 14px;
}

/*
If there's no gutter space, whe'll hide anything
in the gutter that extraneous (not needed perse).
Any gutter content we keep visible (such as the backlink)
will appear at the top of the content area. (directly below the page header)
*/
@media (max-width: 1023px) {
  .gutter_inpagelinks.gutter_inpagelinks {
    display: none;
  }
}