html {
  --footer-panel-background: linear-gradient(180deg, #5F0EAE 0%, #9255E2 100%);
  --footer-panel-padding-top: 51px;
  --footer-panel-padding-bottom: 20px;
  --footer-heading-font: bold 17px/24px var(--rtd-text-fontfamily);
  --footer-text-font: 300 17px/24px var(--rtd-text-fontfamily);
  --footer-text-color: #FFFFFF;
  --footer-bottombar-padding: 20px 0 25px 0;
  --footer-bottombar-color: var(--color-theme);
  --footer-bottombar-font: 14px/22px var(--rtd-text-fontfamily);
  --footer-bottombar-item-spacing: 25px;
}

@media (max-width: 550px) {
  html {
    --footer-panel-padding-top: 40px;
    --footer-panel-padding-bottom: 40px;
    --footer-heading-font: bold 16px/24px var(--rtd-text-fontfamily);
    --footer-text-font: 300 16px/22px var(--rtd-text-fontfamily);
    --footer-bottombar-padding: 15px 0 15px 0;
  }
}
/* stuff which relies on a @media query is easyer to have one element
   down in the tree, so any overrides for mobile are picked up
*/
body {
  --footer-panel-radius: var(--contentpanels-radius-outsidegutter);
}