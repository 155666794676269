@page {
  size: A4;
  size: 210mm 297mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 15mm 2mm 15mm 2mm;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  body {
    --whitespace-footer-content: 1cm;
  }
  .consentbar,
  #slidemenu-container,
  .skiplinksbutton__wrapper,
  .header-top,
  .header-top-background,
  .header-menubar,
  .header-menubar-background,
  .deeplinks-wrapper,
  .footer,
  wh-outputtools {
    display: none !important;
  }
}