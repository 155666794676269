@charset "UTF-8";
.person__guest-staff {
  display: flex;
  font-size: 15px;
  margin-bottom: 15px;
}
.person__guest-staff .icon {
  color: var(--color-dogwood-rose);
  font-size: 24px;
  margin-right: 7px;
}

.phone__part:not(:first-of-type)::before {
  content: " ";
}

.phone__part:last-of-type:not(:first-of-type) {
  font-weight: bold;
}

.workdays {
  display: inline-block;
}

.workdays__title {
  margin-bottom: 10px;
}

.workdays__even,
.workdays__odd,
.workdays__days {
  display: grid;
  grid-template-columns: auto 24px 24px 24px 24px 24px;
}

.workdays__even {
  border-bottom: 1px solid rgba(30, 35, 40, 0.15);
}

.workdays__weekcol,
.workdays__day {
  color: #53575B;
  font-size: 10px;
}

.workdays__weekcol {
  padding: 0 6px;
  text-align: right;
}

.workdays__status,
.workdays__day {
  display: flex;
  justify-content: center;
}

.workdays__status--oncampus,
.workdays__status--offcampus,
.workdays__status--free {
  display: block;
  height: 25px;
  width: 12px;
}
.workdays__even .workdays__status--oncampus,
.workdays__even .workdays__status--offcampus,
.workdays__even .workdays__status--free {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.workdays__odd .workdays__status--oncampus,
.workdays__odd .workdays__status--offcampus,
.workdays__odd .workdays__status--free {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.workdays__status--oncampus,
.workdays__color--oncampus {
  background-color: #63B1E5;
}

.workdays__status--offcampus,
.workdays__color--offcampus {
  background-color: rgba(99, 177, 229, 0.5);
}

.workdays__status--free,
.workdays__color--free {
  background-color: rgba(207, 0, 114, 0.5);
}

.workdays__reference {
  color: #53575B;
  font-size: 10px;
  font-style: italic;
  line-height: 13px;
}

.workdays__color {
  border-radius: 100%;
  display: inline-block;
  height: 7px;
  margin-right: 6px;
  width: 7px;
}

.workdays__comment {
  font-size: 13px;
  line-height: 16px;
}

.categorytaglist {
  list-style: none;
  margin: 0;
  padding: 0;
}
.categorytaglist > li > .taglist {
  margin-top: 10px;
}

.taglist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.taglist > li {
  background: var(--color-grey-light);
  border-radius: 3px;
  font-size: 13px;
  height: 35px;
  line-height: 35px;
  padding: 0 16px;
  white-space: nowrap;
}

.ancillary__org {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
}

.publicationlist__group {
  margin-top: 20px;
}

.page--person .searsia__results .searsia__result {
  width: auto;
}

.medialist {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}

.medialist__entry {
  color: var(--color-black);
  display: flex;
  flex-direction: row;
  gap: 30px;
  text-decoration: none;
}
.medialist__entry + .medialist__entry {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .medialist__entry + .medialist__entry {
    margin-top: 20px;
  }
}
.medialist__entry + .medialist__entry:not(:has(.medialist__image)) {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .medialist__entry {
    flex-direction: column;
    gap: 10px;
  }
}

.medialist__image {
  align-self: flex-start;
  flex: none;
  max-width: 255px;
}

.medialist__text h3 {
  text-transform: none;
}
.medialist__text p {
  margin-bottom: 0;
  margin-top: 10px;
}
.medialist__text p.subtitle {
  font-size: 16px;
  font-weight: 300;
  margin-top: 0;
}

#vcard-popover {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}
#vcard-popover.vcard-popover--show {
  display: flex;
}

.vcard-popover__content {
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
  color: var(--color-black);
  display: flex;
  flex: none;
  flex-direction: column;
  font-size: 18px;
  font-weight: 700;
  height: 420px;
  justify-content: center;
  position: relative;
  width: 420px;
}
.vcard-popover__content img {
  height: 50%;
  width: 50%;
}
.vcard-popover__content a {
  color: var(--color-dark-grey);
  font-weight: 300;
}

#vcard-popover-close {
  background: #aaacae;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.person-address-tabs .pagetabs__content {
  border-bottom: 1px solid var(--color-grey-lines);
}

.person-address-tabpanel {
  display: block;
}

.person-address {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .person-address {
    flex-direction: column;
    margin-top: 10px;
  }
}
.person-address p {
  margin-top: 10px;
}

.person-address-image {
  flex: 1;
  max-width: 380px;
  padding-right: 30px;
}
.person-address-image img {
  max-width: 100%;
  width: 350px;
}