@charset "UTF-8";
/*

Grid rows:
1 - .header-top
2 - .header-menubar
3 - .header-crumblepath
4 - .pageheader
5 - .page__body
6 - .footer

z-index:

10 - --zindex-overlap-deeplinks: 10;
11 - --zindex-overlap-contentarea: 11;

11 - .header-menubar-background
12 - .header-menubar
13 - .header-searchpanel-top/bottom/content/closebutton
14 - .header-menubar:hover ~ .header-menubar-background
15 - .header-menubar:hover

Usage:

- This is an 12 column design setup
- Inbetween each column is a gutter (which is a column in our grid-template-columns)
- Around these is a extra column meant for visually extending certain page elements outside of the normal 12 column contentarea
- Around this all are two columns to keep whitespace around all content.
  Allowing even the extended content or backgrounds to be kept from the viewport's edge.
- For visually pleasing wide panels (such as pageheader/menu/footer)
  we have a --pagegrid-col-verywide-outsidegutter-bg.
  When the viewport isn't wide enough to generate extra whitespace around the columns (excluding the outer left and right column)
  this CSS variable will be changed to fully extend to the viewports outer edges.
  (because it's ugly if these very wide opaque background contents have only a small whitespace around them..
  The need to either be free or fully fill up the full width for a clean uncluttered visual look)

- To use the grid on a container:

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);


It handles:
- footer pushed to bottom of viewport (or page)
- column layout
- ability for deeplinks hovering in contentarea
- topmenu bar scrolling away
- menubar getting sticky
- background for menubar sticky, but scrolling farther to give appearance of menubar shrinking
- menubar disappears when content doesn't fit (flex wrapping into the overflow of the menubar)

*/
/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
html, body {
  padding: 0;
  margin: 0;
}

/*
DEPRECATED CSS variables
Some older sites used this (remove when this version of the grid-layout has been backported to older sites)

--pagegrid-header-protrude-max: 120px;
--pagegrid-gutteroutside: 30px; // used in some older sites for mobile layout
--pagegrid-verywide-maxwidth: 1224px; // ZVI ?
--header-to-content-margin: var(--header-plain-margin-to-content);
*/
html {
  /* Basic pagegrid definitions */
  --pagegrid-sidepadding: 15px;
  /* The gutter in which backgrounds and special content can protrude */
  --pagegrid-gutteroutside-normal-min: 30px;
  --pagegrid-gutteroutside-normal-max: 145px;
  --pagegrid-columns: 12;
  --pagegrid-gutter: 30px;
  --pagegrid-columnwidth: 65px;
  /*
  NOTE: code in spc-pagegrid-filters/index.es will update the following CSS variables:
      - --pagegrid-current-sidepadding
      - --pagegrid-current-sidegutter
  */
  --pagegrid-template-columns:
            var(--pagegrid-coldef-sidemargin)
            var(--pagegrid-coldef-gutteroutside)
            repeat( calc(var(--pagegrid-columns) - 1)
                  , minmax(0, 100fr) var(--pagegrid-gutter)
                  )
            minmax(0, 100fr)
            var(--pagegrid-coldef-gutteroutside)
            var(--pagegrid-coldef-sidemargin);
  --pagegrid-maxwidth: calc( var(--pagegrid-columnwidth) * var(--pagegrid-columns)
                           + var(--pagegrid-gutter) * (var(--pagegrid-columns) - 1)
                           + var(--pagegrid-gutteroutside-normal-max) * 2
                           );
  --pagegrid-maxwidth-nogutter:
                 calc( var(--pagegrid-columnwidth) * var(--pagegrid-columns)
                     + var(--pagegrid-gutter) * (var(--pagegrid-columns) - 1)
                     );
  --pagegrid-width-contentarea:
                 min( calc(100% - var(--pagegrid-sidepadding) * 2)
                    , var(--pagegrid-maxwidth)
                    );
  --pagegrid-width-contentarea-nogutter:
                 min( calc(100% - var(--pagegrid-sidepadding) * 2 - var(--pagegrid-gutteroutside-normal-min) * 2 )
                    , var(--pagegrid-maxwidth-nogutter)
                    );
  /* The whitespace around all content which isn't supported to
     touch the viewport. Usually anything that isn't a background color/image.
  */
  --pagegrid-coldef-sidemargin:
          max( var(--pagegrid-sidepadding) /* At least the minimum requireded whitespace */
             , calc((100% - 1400px) / 2) /* Distribute left over space 1400 */
             );
  /* Allow gutters to be smaller so we can fit small screen */
  /****** FIXME: move to site specific stylesheet ****/
}
@media (max-width: 550px) {
  html {
    --pagegrid-gutter: 5px;
    --pagegrid-sidepadding: 5px;
  }
}
@media (max-width: 750px) {
  html {
    --pagegrid-rowsize-header-top: min-content;
    --site-topbar-min-height: 50px;
    --site-menubar-min-height: 50px;
    --site-menubar-grid-column: var(--pagegrid-col-verywide);
    --site-menubar-padding-top: 0px;
    --site-menubar-padding-bottom: 0px;
  }
  html .header-top {
    display: grid !important;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  html .header-menubar__mainorganization, html .header-menubar__spacer {
    display: none;
  }
  html .header-menubar__backnav__home div {
    display: inline-block;
  }
  html .header-menubar__backnav__home div + div::before {
    content: " ";
  }
}
@media (min-width: 751px) {
  html {
    --site-menubar-height: auto;
    --pagegrid-rowsize-header-top: auto;
    --site-menubar-grid-column: var(--pagegrid-col-verywide-outsidegutter-text);
    --site-menubar-padding-top: 17px;
    --site-menubar-padding-bottom: 11px;
  }
  html .header-top, html .header-top-background {
    display: none;
  }
}
@media (max-width: 640px) {
  html {
    --pagegrid-gutteroutside-normal-min: 10px;
  }
}
@media (max-width: 767px) {
  html {
    --pagegrid-coldef-gutteroutside: var(--pagegrid-gutteroutside-normal-min);
  }
}
@media (min-width: 768px) {
  html {
    --pagegrid-coldef-gutteroutside: clamp( var(--pagegrid-gutteroutside-normal-min)
                                          , calc((100% - var(--pagegrid-gutteroutside-normal-min) * 2 - var(--pagegrid-width-contentarea-nogutter)) / 2)
                                          , var(--pagegrid-gutteroutside-normal-max)
                                          );
  }
}
@media print {
  html {
    --pagegrid-coldef-gutteroutside: 0px; /* FIXME */
  }
}

body {
  --pagegrid-col-default-max8col: var(--pagegrid-col-default);
}
@media print {
  body {
    --pagegrid-col-narrow: var(--pagegrid-col-verywide);
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}

.deeplinks-wrapper {
  left: var(--pagegrid-gutteroutside-normal-min);
}

html {
  --pagegrid-gutter-left-full: 3 / 8; /* no spacing to verywide content */
  --pagegrid-gutter-left: 2 / 6;
  --pagegrid-gutter-right: 23 / 26; /* next to verynarrow */
  --pagegrid-col-verynarrow: 9 / span 11;
  /* ZVI also takes the gutter around the 8 columns for it's contentarea */
  --pagegrid-col-narrow: 6 / span 17;
  --pagegrid-col-narrow-left: 6 / span 8; /* 2 column / left */
  --pagegrid-col-narrow-right: 15 / span 8;
  --pagegrid-col-wide: 5 / span 19;
  --pagegrid-col-wide-include-gutter: 4 / span 21;
  --pagegrid-col-verywide: 3 / 26;
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 15 / 26;
  --pagegrid-col-verywide-outsidegutter: 2 / 27;
  --pagegrid-col-verywide-outsidegutter-text: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-verywide-outsidegutter-bg: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-text-edge-left: 2;
  --pagegrid-col-text-edge-right: 27;
  --pagegrid-col-narrow-gutter-right: 22 / span 5; /* contentarea at the right of narrow layout */
  --pagegrid-col-withsidebar-sidebar: 3 / 9;
  --pagegrid-col-withsidebar-content: 10 / 26;
  --pagegrid-col-withsidebar-tabs: 10 / 26; /* tabs are always allowed to use the full horizontal space



/*
Focus with these traits
- gutter in the middle is clear in both left/right focus mode
-
*/
  --pagegrid-col-verywide-focusleft: 3 / 14;
  --pagegrid-col-verywide-focusleftfull: 2 / 14;
  --pagegrid-col-verywide-right-extraspacing: 15 / 26;
  --pagegrid-col-verywide-focusright: 15 / 26;
  --pagegrid-col-verywide-focusrightfull: 15 / 27;
  --pagegrid-col-verywide-left-extraspacing: 3 / 14; /* keep an extra column spacing */
  --widget-cta-focusleft-text-col: 15 / 26;
  --widget-cta-focusleft-image-col: 3 / 14;
  --widget-cta-extra-spacing: min(2.5vw, 50px);
  --widget-cta-keepfromedge: 0px;
  --widget-cta-focusright-text-col: 3 / 14;
  --widget-cta-focusright-image-col: 15 / 26;
  --pagegrid-col-fullwidth: 1 / 28;
  --zindex-overlap-deeplinks: 10;
  --zindex-overlap-contentarea: 11;
}

@media (max-width: 1080px) {
  html {
    --widget-cta-extra-spacing: 0px;
  }
}
@media (max-width: 1023px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-narrow);
    --pagegrid-col-default-left: var(--pagegrid-col-narrow-left);
    --pagegrid-col-default-right: var(--pagegrid-col-narrow-right);
  }
}
/* If the viewport is smaller than our maximum contentarea size,
   we allow the header and footer to take the full width of the viewport and disable border radius on them.
   (This looks better than a small whitespace around them).
*/
@media (max-width: 1400px) {
  html {
    --contentpanels-radius-outsidegutter: 0px !important;
    --contentpanels-radius-large-viewport-edge: 0px;
    --contentpanels-radius-medium-viewport-edge: 0px;
    --contentpanels-radius-small-viewport-edge: 0px;
    --pagegrid-col-text-edge-left: 3;
    --pagegrid-col-text-edge-right: 26;
  }
  .pageheader__background,
  .footer__panel__background,
  .header-searchpanel-top,
  .header-searchpanel-bottom {
    --pagegrid-col-verywide-outsidegutter: 1 / -1;
  }
  .peoplepersonpage.peoplepersonpage .pageheader--type-search.pageheader .pageheader__background {
    grid-column: 1/-1;
  }
}
body {
  display: grid;
  min-height: 100vh;
  grid-template-rows: var(--pagegrid-rowsize-header-top) minmax(0, min-content) minmax(0, min-content) auto 1fr minmax(0, min-content);
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top-background,
.header-top {
  z-index: 1;
}

.deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
  z-index: 9; /* keep over page content */
}

.site-scrollprogressbar {
  z-index: 10;
}

.header-menubar-background {
  z-index: 11;
}

.header-menubar,
.header-filterbar__querycontainer .wh-autocomplete-container {
  z-index: 12;
}

.wh-autocomplete-values {
  z-index: 11;
}

.header-menubar:hover {
  z-index: 15;
}

.header-menubar:hover ~ .header-menubar-background {
  z-index: 14;
}

.page__contentarea__inset {
  --pagegrid-inset-zindex: 1;
}

.deeplinks-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.deeplinks-wrapper.hide_deeplinks {
  opacity: 0;
  pointer-events: none;
}

.page-header__content {
  position: relative;
  z-index: 1;
}

/* Element used by the utwente_design theme code */
.theme__docstate {
  grid-row: 1;
  grid-column: 1;
}

.skiplinksbutton__wrapper {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.header-top {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.header-top-background {
  grid-row: 1;
}

.header-menubar-background {
  grid-row: 2;
}

.header-menubar {
  grid-row: 2;
  grid-column: 1;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__background {
  grid-row: 1/span 3;
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: #F2EFED;
}

.header-crumblepath {
  grid-row: 3;
}

body > .pageheader {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page__body .pageheader {
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__content {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page__body {
  grid-row: 5;
  grid-column: var(--pagegrid-col-fullwidth);
}

.footer {
  grid-row: 6;
  grid-column: var(--pagegrid-col-fullwidth);
}