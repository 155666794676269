.itemslist
{
  /* compensate for invisible padding-bottom adding to the percieved whitespace */
  margin-bottom: calc(-1 * var(--itemslist-item-pad-v));
}

.itemslist__item
{
  display: flex;
  align-items: center;

  color: inherit;
  text-decoration: none;
  padding: var(--itemslist-item-pad-top) var(--itemslist-item-pad-right) var(--itemslist-item-pad-bottom) var(--itemslist-item-pad-left);
}

/* FIXME: define through CSS variables or CSS classes whether we need this negative margin */
.peoplepersonpage-filters__filters .itemslist__item
{
  margin-left: calc(-1 * var(--itemslist-item-pad-left));
}


a.itemslist__item
{
  border-radius: var(--itemslist-border-radius);
}

a.itemslist__item:hover
{
  background-color: var(--itemslist-item-hover-background);
}

.itemslist__item + .itemslist__item
{
  margin-top: var(--itemslist-spacing);
}

  .itemslist__item__image
, .itemslist__item__imageplaceholder
{
  align-self: start;

  flex:   none;
  width:  var(--itemslist-image-size);
  height: var(--itemslist-image-size);
  border-radius: 50%;

  border: 1px solid var(--itemslist-item-border-color);

  /* text styling for items without image,
     which will show an initial */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F1F2;
  color: var(--color-black);
  font: var(--page-sidebar-font);
  font-weight: 700;
  text-align: center;

  margin-right: 10px;
}

.itemslist__item__meta
{
  font: 13px var(--rtd-text-fontfamily);
}

.itemslist__item__name
{
  font: 600 13px Arial; /* on Windows the bold Universe font at this size has the "i" looking like an "l" */
  margin-bottom: 2px;
}

.itemslist__item__subtitle
{
  color: #53575B;
}
