html
{
  /* Contentpanels
     - panels on the page which use the default or verywide content area width
     - menu dropdowns
  */
  --contentpanels-box-shadow-color:     rgba(0,0,0,0.35);
  --contentpanels-background:           #FFFFFF;
  --contentpanels-box-shadow:           0 2px 17px 0 var(--contentpanels-box-shadow-color);
  --contentpanels-radius:               9px;
  --contentpanels-radius-outsidegutter: 9px; /* this will be reset to 0 when outsidegutter touches the edge of the screen */

  --contentpanels-radius-large:                30px;
  --contentpanels-radius-medium:               10px; /* pageheader, pageheader-searchinput, cta-image */
  --contentpanels-radius-small:                 8px;
  --contentpanels-radius-large-viewport-edge:  var(--contentpanels-radius-large);
  --contentpanels-radius-medium-viewport-edge: var(--contentpanels-radius-medium);
  --contentpanels-radius-small-viewport-edge:  var(--contentpanels-radius-small);



  --contentpanels-padding-v: 30px; /* --widget-highlight-padding:           20px 25px; */
  --contentpanels-padding: 30px;

  --widget-highlight-padding:           20px 25px;

  --contentpanels-padleft:              25px;
  --contentpanels-padright:             25px;

  --contentpanels-color-grey:               #EEEEEE;
  --contentpanels-color-grey-caption-color: #6C6C6C; /* slightly darker color for --common-caption-color when used on the grey background */
}
