.universe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s;
}
.universe svg .universesvg__path--color {
  fill: var(--theme-color);
}
.universe ~ * {
  position: relative;
}
.universe--loaded {
  opacity: 1;
}
.universe__container {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  animation-name: spinuniverse;
  animation-duration: 10000s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.universe__container--reverse {
  animation-name: spinuniverse--reverse;
}
.universe svg {
  position: absolute;
  left: 80%;
  display: block;
}
.universe .universesvg {
  transform: rotate(-25deg);
}
.universe .universesvg--reverse {
  transform: rotate(25deg);
}
.universe .universesvg__path--grey {
  fill: #dcddde;
}
@keyframes spinuniverse {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinuniverse--reverse {
  100% {
    transform: rotate(-360deg);
  }
}