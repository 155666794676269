/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
/*
--pageheader-default-height:
        min( calc(70vw)
           , calc(75vh - var(--siteheader-height-approximation))
           , 450px
           );
*/
@media (min-width: 768px) {
  html {
    --siteheader-height-approximation: 102px;
  }
}
@media (max-width: 767px) {
  html {
    --siteheader-height-approximation: 150px; /* FIXME */
  }
}
.pageheader {
  --rtd-spacing-paragraph: 9px;
}

@media (max-width: 550px) {
  .pageheader {
    --rtd-spacing-paragraph: 8px;
  }
}
html {
  /* Site header settings */
  --site-menubar-background: #FFFFFF;
  --site-menubar-background-grid-column: var(--pagegrid-col-fullwidth);
  --site-menubar-min-height: 99px;
  --pageheader-margin-to-content: 30px;
}

/*

350 - home with search CTA
250 - overview/search page has flatter header with searchbar stickout out half
250 - normal - universe
250 - normal - image (bij People Pages)
*/
.pageheader--base {
  --pageheader-margin-to-content: 40px;
  --pageheader-height: 250px;
  --pageheader-border-radius: 10px;
  --pageheader-searchbar-height: 102px;
  --pageheader-searchbar-border-radius: 10px;
}
@media (max-width: 550px) {
  .pageheader--base {
    --pageheader-col-background: 1 / -1 !important;
    --pageheader-border-radius: 0 !important;
  }
}

@media (max-width: 550px) {
  .peoplepersonpage {
    margin-top: 0;
  }
}
/*
.pageheader--base +
{
  --pageheader-margin-to-content:        40px;

}
*/
.pageheader--search {
  --pageheader-margin-to-content: 40px;
  --pageheader-border-radius: 10px;
  --pageheader-searchbar-height: 102px;
  --pageheader-searchbar-border-radius: 10px;
}

.pageheader--base.pageheader--size-large {
  --pageheader-height: 350px;
  --formcontrols-border-width: 0px;
}
@media (max-width: 550px) {
  .pageheader--base.pageheader--size-large {
    --pageheader-height: 160px; /* bg part 160px */
  }
}

.pageheader--base.pageheader--size-normal {
  --pageheader-height: 250px;
  --formcontrols-border-width: 1px;
  --formcontrols-border-color: #DCDDDE;
}
@media (max-width: 550px) {
  .pageheader--base.pageheader--size-normal {
    --pageheader-height: 160px; /* bg part 160px */
  }
}

html {
  /* Page header settings */
  /*
  Heading spacing
  */
  --header-plain-margin-to-content: clamp(30px, 5vw, 45px); /* side-by-side without image (sticking out) */
  --header-sidebyside-margin-above: 0;
  --header-sidebyside-margin-to-content: clamp(30px, 4vw, 80px);
  --header-sidebyside-border-radius: var(--contentpanels-radius-outsidegutter);
  --header-sidebyside-image-aspectratio: 7 / 4;
  --header-sidebysideflex-margin-above: 30px;
  --header-sidebysideflex-margin-to-content: clamp(60px, 7.5vw, 122px);
  --header-search-margin-above: 28px;
  --header-search-mobile-margin-above: 0px;
  /* Inactive headers */
  --header-wideimage-margin-above: 0;
  --header-wideimage-margin-to-content: clamp(30px, 5vw, 40px);
  --header-imagestickingout-margin-above: 0;
  --header-imagestickingout-margin-to-content: clamp(15px, 2.5vw, 40px); /* 40px desktop design */
  --header-home-margin-above: 0;
  --header-home-margin-to-content: clamp(30px, 5vw, 80px);
  --header-textinset-margin-above: 0;
  --header-textinset-margin-to-content: clamp(40px, 5vw, 47px);
  --header-textinset-cta-margin-above: 0;
  --header-textinset-cta-margin-to-content: clamp(40px, 5vw, 47px);
  /***************************************************************************/
  --pageheader-photo-box-shadow: none; /* used by "sidebyside" and "imagestickingout" */
  --pageheader-photo-border-radius: 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter) 0; /* only round at the page edges


/* Side-by-side header (desktop & tablet)
   - Available for most page types + default type
*/
  --header-sidebyside-image-protrude-amount: 0;
  --header-sidebyside-title-font: clamp(22px,3vw,36px)/135% var(--rtd-heading-fontfamily);
  --header-sidebyside-subtitle-font: 19px var(--rtd-text-fontfamily);
  --header-sidebyside-pagedate-font: 17px var(--rtd-text-fontfamily);
  --header-sidebyside-pagedate-spacing: 12px;
  --header-sidebyside-source-font: 17px var(--rtd-text-fontfamily);
  --header-sidebyside-text-valign: top; /* "start", "center", "end" */
  --header-sidebyside-text-grid-column: var(--pagegrid-col-verywide-focusleftfull);
  --header-sidebyside-text-padside: 30px;
  --header-sidebyside-text-marginbetween: 30px;
  --header-sidebyside-image-grid-column: var(--pagegrid-col-verywide-focusrightfull);
  /* Wide image with title overlay header
     - Available for most page types + default type
     - Single layout mode but scales with viewport width
  */
  --header-wideimage-height: clamp(175px, 30vw, 343px);
  --header-wideimage-label-minheight: 122px;
  --header-wideimage-label-padding-v: clamp( 22px, 3.8vw, 36px);
  /* "Image sticking out" header (desktop & tablet)
     - Used for "profile" page
     - !! (CAK) textinset header shares all font settings
  */
  --header-imagestickingout-image-coverheader-amount: 154px;
  --header-imagestickingout-title-font: bold clamp(22px,3vw,var(--rtd-h1-fontsize))/135% var(--rtd-heading-fontfamily);
  --header-imagestickingout-title-padding: 30px 0 25px 0;
  --header-imagestickingout-pagedate-font: 17px var(--rtd-text-fontfamily);
  --header-imagestickingout-source-font: 17px var(--rtd-text-fontfamily);
  --header-imagestickingout-imagecredits-padding: 15px 30px;
  --header-imagestickingout-pagedate-spacing: 20px;
  --header-textinset-title-font: bold clamp(22px,3vw,var(--rtd-h1-fontsize))/115% var(--rtd-heading-fontfamily);
  --header-textinset-cta-title-font: bold clamp(22px,3vw,var(--rtd-h1-fontsize))/115% var(--rtd-heading-fontfamily);
  /* Home header
     - desktop & mobile layout
     - desktop layout has breakpoints for hiding the decorational elements
  */
  --header-home-height: clamp(232px, 30vw, 329px);
  --header-home-overlap: clamp( 94px, 15vw, 150px); /* MUST be smaller than --header-home-height */
  --header-when-icon-font: 200 18px "Font Awesome 5 Pro";
  /*--header-when-icon-content: var(--fa-calendar-day);*/
  --header-when-icon-content: var(--fa-newspaper);
}

.header-menubar--hidemenuitems {
  /* draw more attention to the toggle buttons */
  --site-menubar-icon-size: 25px;
}

@media (max-width: 949px) {
  html {
    --header-home-margin-above: 0;
    --header-sidebyside-margin-above: 0px;
    --header-sidebysideflex-margin-above: 15px;
    --header-wideimage-margin-above: 0px;
    --site-menubar-icon-size: 20px !important; /* 17px in design, but is very small */
  }
}
@media (max-width: 550px) {
  html {
    /*
    Heading spacing
    */
    --header-sidebyside-pagedate-spacing: 10px;
    --header-sidebyside-margin-to-content: 20px;
    --header-wideimage-margin-above: 0px;
    --header-wideimage-margin-to-content: 20px;
    --header-imagestickingout-margin-above: 10px;
    --header-imagestickingout-margin-to-content: 20px;
    --header-home-margin-to-content: 20px;
    /* Side-by-side header (mobile) */
    --header-sidebyside-title-font: clamp(22px,3vw,38px)/135% var(--rtd-heading-fontfamily);
    --header-sidebyside-subtitle-font: 17px var(--rtd-text-fontfamily);
    --header-sidebyside-pagedate-font: 17px var(--rtd-text-fontfamily);
    --header-sidebyside-source-font: 17px var(--rtd-text-fontfamily);
  }
}