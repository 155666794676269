.project-updates {
  position: relative;
  --project-updates-item-pad-h: 50px;
}

.project-updates::before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 25px;
  border-left: 1px solid var(--theme-color-cta);
  z-index: -1;
}

.project-updates-header {
  position: relative;
  font: var(--rtd-h2-font);
  text-transform: uppercase;
  margin-bottom: 30px;
  padding: 0 var(--project-updates-item-pad-h);
}

.project-update__update {
  position: relative;
  background: #FFFFFF;
  border: 1px solid #DCDDDE;
  border-radius: 10px;
  padding: 35px 0;
}

.project-update__milestone {
  position: relative;
  padding: 28px 0;
}

.project-update__meta {
  position: relative;
  padding: 0 50px;
}

.project-updates-header::before,
.project-update__milestone .project-update__meta::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #FFFFFF;
  border: 1px solid var(--theme-color-cta);
  border-radius: 50%;
  position: absolute;
  left: 22px; /* 25px - 6px / 2 */
  margin-top: 8px;
}

.project-update__meta {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.project-update__what {
  color: var(--theme-color-cta);
  margin-right: 20px;
}

.project-update__when {
  color: #5A5E62;
}

.project-update__title {
  font: var(--rtd-h2-font);
  text-transform: none;
  padding: 0 50px;
  margin-bottom: 15px;
}

.project-update__description {
  padding: 0 50px;
}

.project-update__readmore {
  margin-top: 10px;
  display: inline-block;
  color: #5A5E62;
  text-decoration: underline;
}

.project-update__projectstart {
  width: max-content; /* shrinkwrap */
  margin-top: 30px;
  padding: 0 var(--project-updates-item-pad-h);
  padding: 30px 50px;
  background: var(--theme-color-cta);
  border-radius: 10px;
}

.project-update__projectstart__heading {
  color: #FFFFFF;
  font: var(--rtd-h2-font);
  text-transform: uppercase;
  margin-bottom: 5px;
}

.project-update__projectstart__when {
  color: #FFFFFF;
}