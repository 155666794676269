.ut-listheader {
  color: var(--overviewlist-header-color);
  font: 13px var(--rtd-text-fontfamily);
  padding-bottom: var(--overviewlist-header-padding-bottom);
}

.ut-listheader .filteredoverview__feedback b {
  color: var(--color-black);
}

.personslist .ut-listheader__feedback {
  grid-column: 1/3;
}

.ut-listheader__togglefilters {
  background: none;
  border: 0;
  padding: 6px;
  margin-right: -6px;
  color: var(--common-icon-color);
  font-size: 16px;
  display: var(--peoplepersonpage-filtertoggle-display, flex);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .projectslist .ut-listheader {
    display: grid !important;
  }
  .projectslist .ut-listheader > * {
    display: none;
  }
  .projectslist .ut-listheader__feedback {
    display: flex !important;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between; /* space between the "X results" text and filter toggle button */
  }
}
.personslist--accordionview .ut-listheader,
html.personslist-default-accordionview .personslist--autoview .ut-listheader {
  display: grid !important;
}
.personslist--accordionview .ut-listheader > *,
html.personslist-default-accordionview .personslist--autoview .ut-listheader > * {
  display: none;
}
.personslist--accordionview .ut-listheader__feedback,
html.personslist-default-accordionview .personslist--autoview .ut-listheader__feedback {
  display: flex !important;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between; /* space between the "X results" text and filter toggle button */
}