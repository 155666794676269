@charset "UTF-8";
html {
  --background-icon-angle-down-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' width='18' %3E%3Cpath fill='%23540A51' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
  --background-icon-angle-down-regular-icon: url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23540A51' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E");
  --background-icon-angle-down-regular-size: 12px 12px;
  --background-icon-angle-down-regular: #FFFFFF var(--background-icon-angle-down-regular-icon)
                                        no-repeat
                                        calc(100% - var(--textcontrols_padright))
                                        calc(50% + 2px);
  --background-icon-updown-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==);
  --background-icon-updown: #FFFFFF var(--background-icon-updown-image) no-repeat calc(100% - 10px) 50%;
  /* You can change the fill to the theme-color of the site */
  --background-icon-arrow-down-icon: url("data:image/svg+xml,%3Csvg viewBox='0 0 444 449' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23540A51' d='m439.325 218.1-19.8-19.8c-4.7-4.7-12.3-4.7-17 0l-155.1 155.1v-341.4c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4l-155.1-155.1c-4.7-4.7-12.3-4.7-17 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z' transform='translate(.575)'/%3E%3C/svg%3E");
  --background-icon-arrow-down-size: auto;
  --background-icon-arrow-down-background: #FFFFFF var(--background-icon-arrow-down-icon)
                                           no-repeat
                                           calc(100% - 10px)
                                           calc(50% + 2px);
  --background-icon-calendar-alt: url("data:image/svg+xml,%3Csvg viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23540A51' d='m148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48h-352c-26.5 0-48-21.5-48-48v-352c0-26.5 21.5-48 48-48h48v-52c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128v-52c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346v-298h-352v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z'/%3E%3C/svg%3E");
  --background-icon-calendar-alt-size: auto;
  --formcontrol-pulldown-icon: var(--background-icon-angle-down-regular);
  --formcontrol-pulldown-background: var(--background-icon-angle-down-regular);
  --formcontrol-pulldown-background-size: var(--background-icon-angle-down-regular-size);
}

.multiselect__panel__trigger {
  display: flex;
  align-items: center;
}

.multiselect__valuedisplay__title {
  flex: 1 0 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect__panel__trigger::after {
  flex: none;
  display: block;
  font: var(--fontawesome-regular);
  content: var(--fa-plus-circle);
  color: var(--color-theme);
  font-size: 18px;
}

input[type=date] {
  padding-right: 30px;
  background: #FFFFFF var(--background-icon-calendar-alt) no-repeat calc(100% - 10px) 50%;
  background-size: auto 16px;
}

input[type=date]:focus {
  padding-right: 30px;
}

input[type=date]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 0;
}

/*
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
input[type="date"]
{
  background: #FFFFFF var(--background-icon-calendar-alt) no-repeat calc(100% - 10px) 50%;
  background-size: auto 16px;


  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

}
*/
select {
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  background: var(--formcontrol-pulldown-background);
  background-size: var(--formcontrol-pulldown-background-size);
  /*
    background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  */
  /*
  transparent version, however doesn't seem to work:
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS‌​1uYW1lPSJMYXllciAxIi‌​B4bWxucz0iaHR0cDovL3‌​d3dy53My5vcmcvMjAwMC‌​9zdmciIHZpZXdCb3g9Ij‌​AgMCA0Ljk1IDEwIj48ZG‌​Vmcz48c3R5bGU+LmNscy‌​0ye2ZpbGw6IzQ0NDt9PC‌​9zdHlsZT48L2RlZnM+PH‌​RpdGxlPmFycm93czwvdG‌​l0bGU+PHBvbHlnb24gY2‌​xhc3M9ImNscy0yIiBwb2‌​ludHM9IjEuNDEgNC42Ny‌​AyLjQ4IDMuMTggMy41NC‌​A0LjY3IDEuNDEgNC42Ny‌​IvPjxwb2x5Z29uIGNsYX‌​NzPSJjbHMtMiIgcG9pbn‌​RzPSIzLjU0IDUuMzMgMi‌​40OCA2LjgyIDEuNDEgNS‌​4zMyAzLjU0IDUuMzMiLz‌​48L3N2Zz4=) no-repeat 95% 50%;
  */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 40px;
}

@-moz-document url-prefix() {
  select, select:-moz-focusring, select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    /*border:0;*/
  }
}