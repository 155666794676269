html.page--project .peoplepersonpage {
  --peoplepersonpage-sidebar-preferredwidth: 233px;
  --peoplepersonpage-feedbackbar-height: 33px; /* instead of 46px - spacing to header */
}

.peoplepersonpage__content > .pagetabs {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-color 0.2s 0.1s;
}
.peoplepersonpage__content > .pagetabs.tabstrip--canscrollleft {
  border-left-color: #DCDDDE;
}
.peoplepersonpage__content > .pagetabs.tabstrip--canscrollright {
  border-right-color: #DCDDDE;
}
.peoplepersonpage__content > .pagetabs .pagetabs__content {
  min-width: min-content;
  position: relative;
}
.peoplepersonpage__content > .pagetabs .pagetabs__content::after {
  background: #DCDDDE;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}

#tabpanel-publications .projectpage__topics {
  margin-top: 30px;
  margin-bottom: 50px;
}

#tabpanel-publications h2 + .publications {
  margin-top: 25px;
}

#tabpanel-team {
  --itemslist-image-size: 70px;
  --itemslist-border-radius: 0;
  --itemslist-item-pad-left: 20px;
  --itemslist-item-pad-top: var(--largelisting-header-padding-v);
  --itemslist-item-pad-bottom: var(--largelisting-header-padding-v);
  --itemslist-spacing: 0;
  --itemslist-item-hover-background: transparent;
}
#tabpanel-team .itemslist__item {
  border-bottom: 1px solid var(--common-item-divider-line-color);
}
#tabpanel-team .itemslist .itemslist__item:first-child {
  border-top: 1px solid var(--common-item-divider-line-color);
}
#tabpanel-team a.itemslist__item:hover {
  border-bottom-color: #000000;
}

/*
  #tabpanel-team h2
, #tabpanel-team h3
*/
html.page--project .tabpanel h2.heading2,
html.page--project .tabpanel h3.heading3 {
  margin-top: 40px;
  margin-bottom: 25px;
}
html.page--project .tabpanel * ~ h2.heading2,
html.page--project .tabpanel * ~ h3.heading3 {
  margin-top: 80px;
}

/*
FIXME: shared
*/
.page-sidebar__panel h3 {
  text-transform: none;
}