/*.ut-project-tile*/
.widget-projects
{
  grid-column: var(--pagegrid-col-verywide);
}

.widget-projects .widget__title + .ut-project-tile-list
{
  margin-top: 28px;
}

  .widget-projects + h2
, .widget-projects + .widget-projects
{
  margin-top: 95px;
}

