/************************************************************
*
*  Pagegrid settings
*  (for /webdesigns/shared/components/spc-pagegrid)
*
*/
html {
  --navbar-position: sticky;
  --navbar-flex-direction: column;
  --navbar-top: 0;
  --navbar-height: 70px;
  --navbar-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --navbar-background-color: var(--color-black);
  --navbar-color: var(--color-white);
  --navbar-menu-display: block;
  --navbar-menu-width: 240px;
  --navbar-menu-padding: 10px;
  --navbar-menubutton-width: 60px;
  --navbar-menu-item-height: 40px;
  --navbar-menu-item-padding: 10px;
  --navbar-menu-border-color: var(--color-grey-dark);
  --navbar-menu-border-padding: 0;
  --navbar-menu-background: #FFFFFF; /* utwente_ppp specific for now */
  --navbar-menu-divider-color: var(--common-item-divider-line-color);
}

@media (max-width: 949px) {
  html {
    --navbar-menubutton-width: 50px;
    --site-menubar-icon-size: 25px;
  }
  .header-menubar__utlogo {
    height: 33px;
  }
  .header-menubar__backnav__home {
    font: 700 20px/17px var(--rtd-heading-fontfamily);
  }
  .header-menubar__spacer {
    margin-left: 10px; /* instead of 16px */
    margin-right: 10px;
  }
  /*
  a.header-menubar__identity
  {
    text-decoration: none;
    font: bold 28px var(--rtd-heading-fontfamily);
    font-size: max(20px, min(3.5vw, 28px)); // Chrome 79, Safari 11.1+, iOS 11.3+
    font-size: clamp(20px, 3.5vw, 28px);    // Chrome 79, Safari 13.1+, iOS 13.5+ - https://caniuse.com/#search=clamp()
  }

  .header-menubar__identity > img
  {
    // width: var(--site-menubar-logo-width);
    width: auto;
    height: var(--site-menubar-logo-height);
  }
  */
}
@media (max-width: 480px) {
  .header-menubar__menuitem--infolink {
    display: none !important;
  }
  .header-menubar__menuitem--infolink::before {
    display: none;
  }
}
.header-menubar__buttonbar {
  margin-left: auto;
  display: flex;
  align-self: stretch;
  position: relative; /* for positioning the dropdown menus */
}

.header-menubar__menuitem {
  background: none;
  border: 0;
  position: relative;
  width: var(--navbar-menubutton-width);
  height: var(--navbar-menubutton-width);
  color: #AAACAE;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
}

.header-menubar__menuitem:focus {
  z-index: 10;
}

.header-menubar__menuitem:focus::before {
  opacity: 0;
}

.menubutton__icon {
  grid-column: 1;
  grid-row: 1;
  color: #000000;
  font-size: 16px;
}

.header-menubar__menuitem__currentlang {
  grid-column: 1;
  grid-row: 1;
  margin-top: 30px;
  margin-left: 23px;
  font-size: 12px;
  color: #AAACAE;
}

.header-menubar__menuitem:hover {
  background: #F0F0F0;
}

/* Use a ::before instead of border-left so we can have a smaller
   line than the full hit of the hitarea of the button */
.header-menubar__menuitem ~ .header-menubar__menuitem::before {
  position: absolute;
  left: 0;
  display: block;
  content: "";
  border-left: 1px solid #DCDDDE;
  height: 40px;
}

html.wh-wrdauth--isloggedin .header-menubar__menuitem--account::after {
  background-color: #00ff00;
  border-radius: 100%;
  content: "";
  height: 5px;
  position: absolute;
  right: calc((var(--navbar-menubutton-width) - 16px) / 2 - 2px);
  top: calc((var(--navbar-menubutton-width) - 16px) / 2 - 3px);
  width: 5px;
}

/*
ws2021 - .contentpart__topmenuitem
ppp    - .header-menubar__menuitem
*/